import * as React from "react";
import './DangerButton.scss'
interface IDangerButtonProps {
  text: string;
  className?: string;
  onClick: () => void;
}

const DangerButton: React.FunctionComponent<IDangerButtonProps> = (props) => {
  const { text, className, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn-danger ${className ?? ""}`}>
      {text}
    </button>
  );
};

export default DangerButton;