import axios from "axios";
import { useContext, useRef, useState } from "react";
import { BASE_PATH } from "../api/axios";
import { IAdvListConfig } from "../types/data/AdvListConfig";
import { IAdvListGeneralTranslations } from "../types/responses/translations/advListTranslations";
import { IAdvSearchForm } from "../types/data/advSearchForm";
import {
  IPaginationObject,
  paginationInitialObject,
} from "../types/pagination";
import { IMainTranslationData } from "../types/responses/translations/generalTranslations";
import { SystemMessageContext } from "../store/SystemMessageProvider";
import { SystemMessage } from "../types/emuns/systemMessage";
import { IListCard } from "../types/data/requestsData";

export const useAdvListData = () => {
  const { openNotificationWindow } = useContext(SystemMessageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [listTranslations, setListTranslations] =
    useState<IAdvListGeneralTranslations>();
  const [mainTranslations, setMainTranslations] =
    useState<IMainTranslationData>();
  const [data, setData] = useState<IListCard[]>();
  const [config, setConfig] = useState<IAdvListConfig>();

  const [count, setCount] = useState({
    total: 0,
    onModeration: 0,
  });

  const prev = async function (params: any | null = null) {
    try {
      if (pagination.current.data.prev_page_url) {
        setIsLoading(true);
        const res = await axios.get(pagination.current.data.prev_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const next = async function (params: any | null = null) {
    try {
      if (pagination.current.data.next_page_url) {
        setIsLoading(true);
        const res = await axios.get(pagination.current.data.next_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const click = async function (
    url: string,
    params: any | null = null
  ) {
    try {
      if (url) {
        setIsLoading(true);
        const res = await axios.get(url, { params });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const pagination = useRef<IPaginationObject>({
    next,
    prev,
    click,
    data: paginationInitialObject,
  });
  //get translations
  const getListTranslations = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        BASE_PATH + "/api/advertisement/get/list/translations"
      );
      setMainTranslations(res.data.main)
      setListTranslations(res.data.translations);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  //load adv data
  const loadListData = async (
    type: string,
    params: IAdvSearchForm | null = null
  ) => {
    const url =
      BASE_PATH + `/api/advertisement/get/list/advertisements/${type}`;
    try {
      setIsLoading(true);
      const res = await axios.get(url, {
        params,
      });
      if (res.data.advertisements) {
        const { data, ...rest } = res.data.advertisements;
        pagination.current.data = rest;
        setCount({
          total: res.data.all,
          onModeration: res.data.onModeration,
        });
        setData(data);
      }
      setConfig(res.data.config);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const copyAdv = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await axios.post(BASE_PATH + "/api/advertisement/copy", {
        id,
      });
      if (res.status === 201) {
        setIsLoading(false);
        openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Advertisement copied successfuly')
        return true;
      }
    } catch (error) {
      openNotificationWindow(SystemMessage.ERROR, 'Error', 'Somthing went wrong')
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAdv = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await axios.post(BASE_PATH + "/api/advertisement/delete", {
        id,
      });
      if (res.status === 200) {
        openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Advertisement delete successfuly')
        return true;
      }
    } catch (error) {
      openNotificationWindow(SystemMessage.ERROR, 'Error', 'Somthing went wrong')

    } finally {
      setIsLoading(false);

    }
  };
  const publish = async (id: number) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        BASE_PATH + `/api/advertisement/edit/send-to-moderation`,
        { id }
      );
      openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Advertisement published successfuly')
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    listTranslations,
    mainTranslations,
    data,
    count,
    config,
    pagination,
    loadListData,
    copyAdv,
    deleteAdv,
    getListTranslations,
    publish,
  };
};
