import * as React from "react";
import { Button, Select, Space } from "antd";
import { StoreContext } from "../../../store/StoreProvider";
import "./general.scss";
import {  FormikProps } from "formik";
import { IGeneralTranslations } from "../../../types/responses/translations/generalTranslations";
import { AdvFields } from "../../../types/emuns/advfields";
interface IGeneralProps {
  innerRef: React.MutableRefObject<FormikProps<any> | undefined>;
  translations: IGeneralTranslations;
  onChange?: (value: string) => void;
}

const General: React.FunctionComponent<IGeneralProps> = (props) => {
  const { store, setUpdatedLang } = React.useContext(StoreContext);
  const [langOptions, setLangOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [defaultValue, setDefaultValue] = React.useState<string | null>(null);
  const { translations, onChange, innerRef } = props;
  const submitHandler = () => {
    if (innerRef.current) {
      innerRef.current.submitForm();
    }
  };
  const changeHandler = (value: string) => {
    if (value !== innerRef.current?.values.updated_lang) {
      setUpdatedLang(value);
      if (onChange) {
        onChange(value);
      }
      innerRef.current?.setFieldValue(AdvFields.UPDATED_LANG, value);
    }
  };
  React.useEffect(() => {
    if (!store.langs) {
      return;
    }
    const langs: { label: string; value: string }[] = [];
    store.langs.forEach((el) => {
      langs.push({
        label: el.name.charAt(0).toUpperCase() + el.name.slice(1),
        value: el.code,
      });
      if (el.code === store.updated_lang) {
        setDefaultValue(el.name.charAt(0).toUpperCase() + el.name.slice(1));
      }
    });
    setLangOptions(langs);
    innerRef.current?.setFieldValue(AdvFields.UPDATED_LANG, store.updated_lang);
  }, [store.langs, store.updated_lang]);
  return (
    <div className="general">
      <h3 className="general-title title">{translations.title}</h3>
      <p className="editing-lang-info">{translations.lang_change}</p>
      <div className="editing-lang-selector">
        <p className="editing-lang-selector-text">{translations.lang_select}</p>
        {langOptions && defaultValue && (
          <Space
            style={{
              width: "100%",
            }}
            direction="vertical"
          >
            <Select
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              value={store.updated_lang}
              onChange={changeHandler}
              options={langOptions}
            />
          </Space>
        )}
        <Button onClick={submitHandler} className="save-button">
          {translations.button}
        </Button>
      </div>
    </div>
  );
};

export default General;
