import * as React from "react";
import { StoreContext } from "../../store/StoreProvider";
import './index.scss'
import Image from "../image/ImageComp";
import { BASE_PATH } from "../../api/axios";
interface IUserInfoProps {
  // image?: string;
  // name: string;
  // id: string;
}

const UserInfo: React.FunctionComponent<IUserInfoProps> = (props) => {
  const { store } = React.useContext(StoreContext);
  const path = BASE_PATH + '/storage' + store.user?.image;
  return (
    <div className="user-info--sb">
      {store.user?.image ?
        <Image imageSrc={path} />
        :
        <div className="empty-image">
          <i className="fa-regular fa-user"></i>
        </div>
      }
      <div>
        <p className="user-role">{store.user && store.user.role_translation}</p>
        {
          <div className="user-info--sb-name">
            {
              store.user &&
                (store.user.name && store.user.last_name) ? (store.user.name + ' ' + store.user.last_name) :
                <div className="tooltip">
                  <i className="fa-solid fa-circle-exclamation"></i>
                  <span className="tooltiptext">You see this because you didn't set name and last name for current language</span>
                </div>
            }
          </div>
        }
        <p>ID {store.user && store.user.id}</p>
      </div>
    </div>
  );
};

export default UserInfo;
