import * as React from 'react';
import { Form } from './Form';
import { RequestsFields } from '../../types/emuns/requestsFields';
import TextArea from '../inputs/TextArea';
import { IReplyForm, replyInitialValues, replyShema } from '../../validation/replyShema/replyShema';

interface IReplyFormProps {
    onSubmit: (values: IReplyForm) => void;
    innerRef: React.MutableRefObject<any>

}

const ReplyForm: React.FunctionComponent<IReplyFormProps> = (props) => {
    return <Form<IReplyForm>
        schema={{
            initialValues: replyInitialValues,
            onSubmit: props.onSubmit,
            validationSchema: replyShema,
        }}
        innerRef={props.innerRef}
        enableReinitialize>
        <TextArea name={RequestsFields.REPLY_MESSAGE} />
    </Form>;
};

export default ReplyForm;
