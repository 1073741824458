import * as React from 'react';
import ImageComp from '../../image/ImageComp';
import { BASE_PATH } from '../../../api/axios';

interface IMainPreviewImageProps {
    image?: string | null;
    isPrewiew?: true
}

const MainPreviewImage: React.FunctionComponent<IMainPreviewImageProps> = (props) => {
    const { image, isPrewiew } = props
    if (!isPrewiew) {
        return null;
    }
    return <>
        {
            image ? (
                <ImageComp
                    imageSrc={BASE_PATH + "/storage" + image}
                />
            ) : (
                <div className="empty-image">
                    <i className="fa-solid fa-house"></i>
                </div >
            )}
    </>
};

export default MainPreviewImage;
