import { Field, Form, Formik } from "formik";
import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { login } from "../api/axios";
import { StoreContext } from "../store/StoreProvider";
import "./pages.scss";
import Loader from "../components/loader/Loader";
interface ILoginProps { }

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const { setUser } = React.useContext(StoreContext);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(6).required("Required"),
  });
  const loginHandler = async (user: { email: string; password: string }) => {
    const formData = new FormData();
    setIsLoading(true);
    for (const key in user) {
      if (Object.prototype.hasOwnProperty.call(user, key)) {
        const element = user[key as keyof typeof user];
        formData.append(key, element);
      }
    }

    try {
      const res = await login(formData);
    setIsLoading(false);
    if (res.status === 200) {
      setUser(res.data.user);
      navigate("/account/profile");
    }
  } catch (error) {
      setIsLoading(false);
    }
    
  };
  return (
    <div className="container test">
      <div className="form-wrapper">
        <h2 className="title">Login</h2>
        <div className="login-form">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              // same shape as initial values
              loginHandler(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="login-form-field-wrapper">
                  <p className="label">Email</p>
                  <Field name="email" />
                  {errors.email && touched.email ? (
                    <div className="error_msg">{errors.email}</div>
                  ) : null}
                </div>
                <div className="login-form-field-wrapper">
                  <p className="label">Password</p>
                  <Field name="password" type={"password"} label={"Password"} />
                  {errors.password && touched.password ? (
                    <div className="error_msg">{errors.password}</div>
                  ) : null}
                </div>
                <button type="submit" className="submit-btn">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <NavLink to={"/register"}>Register</NavLink>
      </div>
      {isLoading && <Loader text="Loading..." />}
    </div>
  );
};

export default Login;
