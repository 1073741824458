import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';
import { ICountry } from '../../../../types/data/advConfig';

interface IAdvListCountryProps {
    config: ICountry[]
    label: string;
    defaultTransl: string;
}

const AdvListCountry: React.FunctionComponent<IAdvListCountryProps> = (props) => {
    const { config, label, defaultTransl } = props;
    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvSearchFields.COUNTRY, value)
    }
    const countries = [{ label: defaultTransl, value: '0' }].concat(config.map((el) => ({ label: el.name, value: el.code2 })))
    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            name={AdvSearchFields.COUNTRY}
            defaultValue={'0'}
            spaceWidth={100}
            selectWidth={100}
            options={countries}
            onChange={changeHandler}
            allowClear
            showSearch />
    </div>
};

export default React.memo(AdvListCountry);
