import { Field, Form, Formik } from "formik";
import * as React from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { BASE_PATH, register } from "../api/axios";
import Cookies from "js-cookie";
import axios from "axios";
import "./pages.scss";
import Loader from "../components/loader/Loader";
interface IRegisterProps { }

const Register: React.FunctionComponent<IRegisterProps> = (props) => {
  const [responseMsg, setResponseMsg] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [roles, setRoles] = React.useState<Array<{
    name: string;
    role: string;
  }> | null>(null);
  const SignupSchema = Yup.object().shape({
    role: Yup.string().required(),
    name: Yup.string().min(2).required("Required"),
    last_name: Yup.string().min(2).required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(6).required("Required"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords must match"
    ),
  });
  const registerHandler = async (user: {
    name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
  }) => {
    const formData = new FormData();
    setIsLoading(true);
    for (const key in user) {
      if (Object.prototype.hasOwnProperty.call(user, key)) {
        const element = user[key as keyof typeof user];
        formData.append(key, element);
      }
    }
    try {
      const res = await register(formData);
      setIsLoading(false)
      if (res.status === 201) {
        Object.keys(Cookies.get()).forEach(function (cookieName) {
          Cookies.remove(cookieName);
        });
        setResponseMsg(true);
      }

    } catch (error) {
      setIsLoading(false)
    }
  };
  const load = async () => {
    const res = await axios.get(BASE_PATH + "/api/app/roles");
    setRoles(res.data);
  };
  React.useEffect(() => {
    load();
  }, []);
  return (
    <div className="container test">
      <div className="form-wrapper">
        <h2 className="title">Register</h2>
        {responseMsg && (
          <span>
            Your are successfuly registered and can{" "}
            <strong>
              <NavLink to={"/"}>Login</NavLink>
            </strong>{" "}
            now
          </span>
        )}
        <div className="register-form">
          <Formik
            initialValues={{
              role: "",
              name: "",
              last_name: "",
              email: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => registerHandler(values)}
          >
            {({ errors, touched, handleChange, handleBlur }) => {
              return (
                <Form>
                  {roles && (
                    <select
                      name="role"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ display: "block" }}
                    >
                      <option value="">Select role</option>
                      {roles.map((el) => (
                        <option value={el.role}>{el.name}</option>
                      ))}
                    </select>
                  )}
                  <div>
                    {errors.role && touched.role ? (
                      <div className="error_msg">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="login-form-field-wrapper">
                    <p className="label">Name</p>
                    <Field name="name" />
                    {errors.name && touched.name ? (
                      <div className="error_msg">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="login-form-field-wrapper">
                    <p className="label">Last Name</p>
                    <Field name="last_name" />
                    {errors.last_name && touched.last_name ? (
                      <div className="error_msg">{errors.last_name}</div>
                    ) : null}
                  </div>
                  <div className="login-form-field-wrapper">
                    <p className="label">Email</p>
                    <Field name="email" />
                    {errors.email && touched.email ? (
                      <div className="error_msg">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="login-form-field-wrapper">
                    <p className="label">Password</p>
                    <Field
                      name="password"
                      type={"password"}
                      label={"Password"}
                    />
                    {errors.password && touched.password ? (
                      <div className="error_msg">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="login-form-field-wrapper">
                    <p className="label">Password repeat</p>
                    <Field name="password_confirmation" type={"password"} />
                    {errors.password_confirmation &&
                      touched.password_confirmation ? (
                      <div className="error_msg">
                        {errors.password_confirmation}
                      </div>
                    ) : null}
                  </div>
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <NavLink to={"/"}>Login</NavLink>
      </div>
      {isLoading && <Loader text="Loading..." />}

    </div>
  );
};

export default Register;
