import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { BASE_PATH } from "../api/axios";
import {
  IPaginationObject,
  paginationInitialObject,
} from "../types/pagination";
import { IMainTranslationData } from "../types/responses/translations/generalTranslations";
import { StoreContext } from "../store/StoreProvider";
import { IListCard } from "../types/data/requestsData";
import { IRequestsTranslations } from "../types/responses/translations/applicationTranslations";
import { IRequestsConfig } from "../types/data/requestsConfig";
import { IRequestsForm } from "../types/data/requestsForm";
import { FormikValues } from "formik";

export const useRequestsData = () => {
  const { store, setLangIschanged } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(false);
  const [applicationTranslations, setApplicationTranslations] = useState<IRequestsTranslations>();
  const [mainTranslations, setMainTranslations] = useState<IMainTranslationData>();
  const [data, setData] = useState<IListCard[]>();
  const [config, setConfig] = useState<IRequestsConfig>();

  const prev = async function (params: any | null = null) {
    try {
      if (pagination.current.data.prev_page_url) {
        setIsLoading(true);
        const res = await axios.get(pagination.current.data.prev_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const next = async function (params: any | null = null) {
    try {
      if (pagination.current.data.next_page_url) {
        setIsLoading(true);
        const res = await axios.get(pagination.current.data.next_page_url, {
          params,
        });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const click = async function (
    url: string,
    params: any | null = null
  ) {
    try {
      if (url) {
        setIsLoading(true);
        const res = await axios.get(url, { params });
        if (res.data.advertisements) {
          const { data, ...rest } = res.data.advertisements;
          pagination.current.data = rest;
          setData(data);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const pagination = useRef<IPaginationObject>({
    next,
    prev,
    click,
    data: paginationInitialObject,
  });


  //get translations
  const getListTranslations = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        BASE_PATH + "/api/requests/get/translations"
      );
      setMainTranslations(res.data.main)
      setApplicationTranslations(res.data.translations);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const submit = (params: IRequestsForm | null = null) => {
    loadApplicationData(params)
  }

  const replySubmit = async (values: FormikValues, params: IRequestsForm | null = null) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('reply', JSON.stringify(values))
      const res = await axios.post(
        BASE_PATH + `/api/requests/create-reply`, formData
      );
      if (res.status === 200) {
        return true;
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }
  const toArchive = async (id: number) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('id', JSON.stringify(id))
      const res = await axios.post(
        BASE_PATH + `/api/requests/to-archive`, formData
      );
      if (res.status === 200) {
        return true;
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  //load adv data
  const loadApplicationData = async (
    params: IRequestsForm | null = null
  ) => {
    const url = BASE_PATH + `/api/requests/get/list`;
    try {
      setIsLoading(true);
      const res = await axios.get(url, {
        params,
      });
      if (res.data.applications) {
        const { data, ...rest } = res.data.applications;
        pagination.current.data = rest;
        setData(data);
      }
      setConfig(res.data.config);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    getListTranslations();
    loadApplicationData();
  }, [])
  useEffect(() => {
    if (store.langIsChanged) {
      getListTranslations();
      loadApplicationData();
      setLangIschanged(false)
    }

  }, [store.langIsChanged])
  return {
    isLoading,
    data,
    applicationTranslations,
    mainTranslations,
    config,
    pagination,
    loadApplicationData,
    submit,
    replySubmit,
    toArchive
  };
};