import * as React from 'react';
import './index.scss';
interface IEditButtonProps {
    name: string;
    onClick: () => void
}

const EditButton: React.FunctionComponent<IEditButtonProps> = (props) => {
    const { name, onClick } = props
    return <div onClick={onClick} className='edit-btn'>
        <i className="fa-regular fa-pen-to-square"></i>
        <p className='delete-btn-text'>{name}</p>
    </div>;
};

export default EditButton;
