import { Checkbox } from "antd";
import { useField, useFormikContext } from "formik";
import * as React from "react";
import { IAdvInitialValues } from "../../types/data/advInitialValues";
import { AdvFields } from "../../types/emuns/advfields";

interface IFilterCheckboxInputProps {
  name: string;
  label: string;
}

const FilterCheckboxInput: React.FunctionComponent<
  IFilterCheckboxInputProps
> = (props) => {
  const { name, label } = props;
  const { values, setValues } = useFormikContext<IAdvInitialValues>();
  const [checked, setChecked] = React.useState(false);
  const onChangeHandler = () => {
    const filters = [...values.filters];
    if (checked) {
      setChecked(false);
      const index = filters.findIndex((el) => el === +name);
      filters.splice(index, 1);
    } else {
      setChecked(true);

      filters.push(+name);
    }
    setValues({ ...values, filters: filters });
  };
  React.useEffect(() => {
    if (values.filters.includes(+name)) {
      setChecked(true);
    }
  }, []);
  return (
    <Checkbox
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChangeHandler}
    >
      {label}
    </Checkbox>
  );
};

export default FilterCheckboxInput;
