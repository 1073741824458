import * as React from "react";
import "./styles.scss";
interface ILogoProps {}

const Logo: React.FunctionComponent<ILogoProps> = (props) => {
  return (
    <a className="logo" href="/">
      <img className="logo-img" src="" alt="logo" />
      <span className="logo-text">AZUM</span>
    </a>
  );
};

export default Logo;
