import { useField } from 'formik';
import * as React from 'react';

interface INumInputProps {
    name: string
    placeholder?: string
}

const NumInput: React.FunctionComponent<INumInputProps> = (props) => {
    const [field, meta] = useField(props.name);

    return <>
        <input
            className="input"
            type="number"
            name={props.name}
            value={field.value}
            onChange={field.onChange}
            placeholder={props.placeholder}
        /><div className="unit-error error-wrapper">
            {meta.touched && meta.error && (
                <span className="error_msg">{meta.error}</span>
            )}
        </div></>
};

export default NumInput;
