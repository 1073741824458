export const butilizePrice = (price: string) => {
    const priceReverse = (price).toString().split('').reverse()
    const acc: string[] = [];
    let accEl = '';
    const prL = priceReverse.length;
    priceReverse.forEach((el, index) => {
        if (prL - index + 1 >= 3) {
            if (accEl.length < 3) {
                accEl += el;
            }
            if (accEl.length === 3) {
                acc.push(accEl)
                accEl = '';
            }
        } else {
            accEl += el
            if (index + 1 === prL) {
                acc.push(accEl);
            }
        }
    })
    return acc.join().split('').reverse().join('');
}