import * as React from 'react';
import { Form } from './Form';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import TextInput from '../inputs/TextInput';
import ApplicationTypeSelect from '../content/applications/selectComponents/RequestsTypeSelect';
import ApplicationPropertySelect from '../content/applications/selectComponents/RequestsPropertySelect';
import ApplicationReplySelect from '../content/applications/selectComponents/RequestsReplySelect';
import ApplicationRespResPersonSelect from '../content/applications/selectComponents/RequestsRespResPersonSelect';
import NumberInput from '../inputs/NumberInput';
import { StoreContext } from '../../store/StoreProvider';
import { IRequestsForm } from '../../types/data/requestsForm';
import { requestsInititalValues } from '../../validation/requestsSearchForm/requestsSearchForm';
import { RequestsFields } from '../../types/emuns/requestsFields';
import { IRequestsConfig } from '../../types/data/requestsConfig';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';

interface IRequestsSearchFormProps {
  innerRef: React.MutableRefObject<any>
  config: IRequestsConfig
  translations: IFormTranslations;
  onClick: (values: IRequestsForm) => void
}

const RequestsSearchForm: React.FunctionComponent<IRequestsSearchFormProps> = (props) => {
  const { innerRef, config, translations, onClick } = props
  const { store } = React.useContext(StoreContext);

  const [visible, setVisible] = React.useState(false);
  const clickHandler = () => {
    setVisible(!visible)
  }
  const submitHandler = ((values: IRequestsForm) => {
    onClick(values)
  })
  return <div className='applications-search-form'>
    {!visible &&
      <div className="filter-show-btn" onClick={clickHandler}>
        <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
      </div>
    }
    <div className={`search-form-wrapper ${visible ? '' : 'hiden'}`}>
      <Form<IRequestsForm>
        schema={{
          initialValues: requestsInititalValues,
          onSubmit: submitHandler,
        }}
        enableReinitialize
        innerRef={innerRef}
      >
        <div className={`search-form`}>
          <h2>{translations.titleRequests} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
          <div className="search-form-inner">
            <TextInput name={RequestsFields.ADV_ID} label={translations.id_label} placeholder={translations.id_placeholder} />
            <NumberInput name={RequestsFields.PHONE_NUMBER} label={translations.phone_label} placeholder={translations.phone_placeholder} />
            <ApplicationTypeSelect config={config.adv_types} label={translations.type_label} defaultTransl={translations.all_placeholder} />
            <ApplicationPropertySelect config={config.property_types} label={translations.property_label} defaultTransl={translations.all_placeholder} />
            <ApplicationReplySelect config={config.statuses} label={translations.status_label} defaultTransl={translations.all_placeholder} />
            {
              store.user?.role !== 'private seller' &&
              <ApplicationRespResPersonSelect label={'Responcible person'} defaultTransl={translations.responce_person_placeholder} />
            }
            <AdvListPagination label={translations.pagination_label} field={RequestsFields.PER_PAGE} />
          </div>
        </div>
        <div className='btns-wrapper'>
          <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
          <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
        </div>
      </Form>
    </div>
  </div>;
};

export default RequestsSearchForm;
