export enum AdvSearchFields {
    COUNTRY = "country",
    CURRENCY = "currency",
    PROPERTY_TYPE = "property_type",
    ROOMS = "rooms",
    STATUS = 'status',
    PRICE_MIN = "price_min",
    PRICE_MAX = 'price_max',
    TOTAL_AREA_MIN = 'total_area_min',
    TOTAL_AREA_MAX = 'total_area_max',
    TOTAL_AREA_UNIT = 'total_area_unit',
    LAND_SIZE_MIN = 'land_size_min',
    LAND_SIZE_MAX = 'land_size_max',
    LAND_SIZE_UNIT = 'land_size_unit',
    PER_PAGE = 'per_page',
    RESPONCE_PERSON = 'responce_person',
}