import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ILeftSidebar } from "../../../types/responses/translations";

interface ISubMenuProps {
  item: ILeftSidebar;
}

const SubMenu: React.FunctionComponent<ISubMenuProps> = (props) => {
  const { item } = props;
  const location = useLocation();
  return (
    <ul>
      {item.submenu &&
        item.submenu.map((submenu, index) => (
          <li
            key={index}
            className={`sub-menu ${
              location.pathname.includes(submenu.url) ? "active" : ""
            }`}
          >
            <NavLink to={`/account/announcements/${submenu.url}`}>
              {submenu.name}
            </NavLink>
          </li>
        ))}
    </ul>
  );
};

export default SubMenu;
