export interface IPaginationLink {
  url: string | null;
  label: string;
  active: boolean;
}
export interface IPaginationData {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: IPaginationLink[];
  next_page_url: string | null;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface IPaginationObject {
  next: (params: any | null) => Promise<void>;
  prev: (params: any | null) => Promise<void>;
  click: (url: string, params: any | null) => Promise<void>;
  data: IPaginationData;
}

export const paginationInitialObject: IPaginationData = {
  current_page: 0,
  first_page_url: "",
  from: 0,
  last_page: 0,
  last_page_url: "",
  links: [],
  next_page_url: null,
  path: "",
  per_page: 0,
  prev_page_url: null,
  to: 0,
  total: 0,
};
