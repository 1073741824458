import * as React from 'react';
import { ISystemMessage } from '../../types/mesages/SystemMessage';
import './systemMessage.scss'
import { SystemMessageContext } from '../../store/SystemMessageProvider';
interface ISystemMessageProps {
}

const SystemMessage: React.FunctionComponent<ISystemMessageProps> = (props) => {
    const {isOpen, type, title, message, closeNotificationWindow} = React.useContext(SystemMessageContext);
    return <>
        {isOpen ? <div className={`system-message system-message--${type}`}>
            <div className="system-message-inner">
                <h3 className='system-message-title'>{title}</h3>
                <p className='system-message-text'>{message}</p>
                <span className='system-message-close-btn'>
                    <i className="fa-solid fa-xmark" onClick={closeNotificationWindow}></i>
                </span>
            </div>
        </div> : null}
    </>
};

export default SystemMessage;
