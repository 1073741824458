import * as React from "react";
import Main from "../layouts/Main";

interface IEmptyPageProps {}

const EmptyPage: React.FunctionComponent<IEmptyPageProps> = (props) => {
  return (
    <Main title="" breadcrumbs={[]}>
      <div className="container content">
        <h2>Will be here soon</h2>
      </div>
    </Main>
  );
};

export default EmptyPage;
