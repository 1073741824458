import { useField } from "formik";
import * as React from "react";

interface INumberInputProps {
  label?: string;
  name: string;
  id?: string;
  image?: string;
  placeholder?: string;
  withError?: true;
  className?: string
}

const NumberInput: React.FunctionComponent<INumberInputProps> = (props) => {
  const { label, name, image, placeholder, withError, className } = props;
  const [field, meta] = useField(props.name);

  return (
    <label className={`label ${className ?? ''} ${withError ? "with-error" : ""}`}>
      <div className="label-wrapper">
        <span className="label-text">{label}</span>{" "}
        {image && <img src={image} alt="" className="label-image" />}
      </div>
      <div className="input-wrapper">
        <input
          className="input"
          type="number"
          name={name}
          value={field.value}
          onChange={field.onChange}
          placeholder={placeholder}
        />
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </label>
  );
};

export default NumberInput;
