import * as React from "react";
import Main from "../layouts/Main";
// import { StoreContext } from "../store/StoreProvider";
import RightSidebar from "../components/navbars/RightSidebar";
import { useAdvertisementData } from "../hooks/useAdvertisementData";
import Loader from "../components/loader/Loader";
import { IAdvTranslatonsData } from "../types/responses/translations/advertisementTranslations";
import {
  IMainTranslationData,
  ISidebarTranslationData,
} from "../types/responses/translations/generalTranslations";
import { IAdvInitialValues } from "../types/data/advInitialValues";
import { advInitialValues } from "../validation/advertisementShema/advShema";
import { removeUndefinedFields } from "../utils/removeUndefinedFields";
import { IAdvConfig } from "../types/data/advConfig";
import AdvForm from "../components/forms/AdvForm";
import { useParams } from "react-router-dom";
import { ApiImageDestination } from "../types/emuns/ImageDestinaionEnum";
import { FormikProps, FormikValues } from "formik";
import { StoreContext } from "../store/StoreProvider";
import SystemMessage from "../components/modals/SystemMessage";
import { ISystemMessage } from "../types/mesages/SystemMessage";

interface IAdvertisementProps {
}

const Advertisement: React.FunctionComponent<IAdvertisementProps> = (props) => {
  const { id } = useParams();
  const { store, setLangIschanged } = React.useContext(StoreContext);
  const [mainTranslations, setMainTranlsations] =
    React.useState<IMainTranslationData | null>(null);
  const [sidebarTranslations, setSidebarTranlations] =
    React.useState<ISidebarTranslationData | null>(null);
  const {
    isLoading,
    advTranslations,
    data,
    config,
    deleteImage,
    loadImageOnServer,
    onSubmit,
    loadData,
    getAdvData,
    loadMultiData,
  } = useAdvertisementData();
  const [translations, setTranslations] = React.useState<IAdvTranslatonsData>();
  const [advConfig, setAdvConfig] = React.useState<IAdvConfig>();
  const [initialValues, setInitialValues] = React.useState<IAdvInitialValues>();
  const innerRef = React.useRef<FormikProps<IAdvInitialValues>>();
  const deleteImageHandler = async (
    image: string,
    destination: ApiImageDestination
  ) => {
    deleteImage(image, destination, id ?? null);
  };
  const loadImageHandler = async (
    file: File,
    destination: ApiImageDestination
  ) => {
    return await loadImageOnServer(file, destination, id ?? null);
  };
  const submitHandler = async (data: FormikValues) => {
    return await onSubmit(data, id ?? null);
  };
  const updateLangChangeHandler = async (value: string) => {
    const res = await loadMultiData(value, id);
    innerRef.current?.setValues({
      ...innerRef.current.values,
      address: res ? res.address ?? "" : "",
      city: res ? res.city ?? "" : "",
      district: res ? res.disctict ?? "" : "",
      description: res ? res.description ?? "" : "",
    });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    loadData(id ?? null);
  }, []);
  React.useEffect(() => {
    if (store.langIsChanged) {
      loadData(id ?? null);
      setLangIschanged(false);
    }
  }, [store.langIsChanged]);
  React.useEffect(() => {
    if (advTranslations && config) {
      setTranslations(advTranslations.translations);
      setMainTranlsations(advTranslations.main);
      setSidebarTranlations(advTranslations.sidebar);
      setAdvConfig(config);
    }
  }, [advTranslations, config]);

  React.useEffect(() => {
    if (!data) {
      setInitialValues(advInitialValues);
    } else {
      const initialValuesObject = removeUndefinedFields(data, advInitialValues);
      setInitialValues(initialValuesObject);
    }
  }, [data]);
  React.useEffect(() => {
    if (!id) {
      getAdvData(null);
    }
  }, [id]);
  return (
    <>
      <Main
        title={id ? mainTranslations?.titleEdit : mainTranslations?.titleCreate}
        breadcrumbs={[
          {
            link: "/account",
            descr: mainTranslations?.breadcrumbs.home,
          },
          {
            link: "/account/profile",
            descr: mainTranslations?.breadcrumbs.profile,
          },
          {
            link: "/account/anoucements",
            descr: mainTranslations?.breadcrumbs.annoucements,
          },
          {
            link: id ? "/accout/edit-ad" : "/accout/create-ad",
            descr: id
              ? mainTranslations?.breadcrumbs.edit
              : mainTranslations?.breadcrumbs.create,
          },
        ]}
      >
        {translations && advConfig && initialValues && (
          <>
            <div className="adv-content">
              <AdvForm
                translations={translations}
                innerRef={innerRef}
                deleteImage={deleteImageHandler}
                loadImageOnServer={loadImageHandler}
                initialValues={initialValues}
                config={advConfig}
                onSubmit={submitHandler}
              />
            </div>
            <RightSidebar
              translations={sidebarTranslations}
              innerRef={innerRef}
              onChange={updateLangChangeHandler}
              publicMark
              langMark
            />
          </>
        )}
      </Main>
      {isLoading && <Loader text={"Loading"} />}
    </>
  );
};

export default Advertisement;
