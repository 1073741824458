import * as React from 'react';
import { IListCard } from '../../../types/data/requestsData';
import EmptyList from '../AdvList/EmptyList';
import RequestsCard from '../../card/RequestsCard';
import { IRequestsListTranslations } from '../../../types/responses/translations/applicationTranslations';
import ApplicationReplyListCard from '../../card/RequestsReplyListCard';

interface IRequestsListProps {
    data?: IListCard[];
    translations: IRequestsListTranslations;
    preview: (index: number) => void
    toArchive: (index: number) => void;

}

const RequestsList: React.FunctionComponent<IRequestsListProps> = (props) => {
    const { data, translations, preview, toArchive } =
        props;
    const previewHandler = (index: number) => () => {
        preview(index)
    }
    return <>
        {data && data.length > 0 ? (
            data.map((card, index) => (
                <RequestsCard
                    card={card}
                    key={index}
                    child={<ApplicationReplyListCard card={card} translations={translations} toArchive={toArchive} preview={previewHandler(index)} />}
                />
            ))
        ) : (
            <EmptyList text={translations.empty_list} />
        )}
    </>;
};

export default RequestsList;
