import { array,  object, string } from "yup";
import { IProfileInitialValues } from "../../types/data/ProfileInitialValues";
import { phoneShema } from "../general/ptonesShema";



export const profileInitialValues: IProfileInitialValues = {
  image: '',
  name: "",
  last_name: "",
  langs: [],
  email: "",
  phones: [],
  timezone: "",
  email_visible: false,
  updated_lang: ""
};

export const profileSchema = object({
  name: string().min(2).required(),
  last_name: string().min(2).required(),
  langs: array().of(string()).min(1).required(),
  email: string().min(2).required(),
  phones: array().of(phoneShema).min(1).required(),
  timezone: string().min(1).required(),
});
