import { Checkbox } from 'antd';
import { useField } from 'formik';
import * as React from 'react';

interface ICheckboxInputProps {
    checkboxName: string;
    checkboxLabel: string;
}

const CheckboxInput: React.FunctionComponent<ICheckboxInputProps> = (props) => {
    const { checkboxName, checkboxLabel } = props;
    const [field] = useField(props.checkboxName)
    return <Checkbox type="checkbox" name={checkboxName} checked={field.value} onChange={field.onChange}>{checkboxLabel}</Checkbox>;
};

export default CheckboxInput;
