import * as React from 'react';
import Cookies from "js-cookie";
import { StoreContext } from '../store/StoreProvider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_PATH } from '../api/axios';

interface ILogoutProps {
}

const Logout: React.FunctionComponent<ILogoutProps> = (props) => {
  const { setUser } = React.useContext(StoreContext);
  const navigate = useNavigate()
  const logout = async () => {
    const res = await axios.get(BASE_PATH + '/api/logout')
    if (res.status === 200) {
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        Cookies.remove(cookieName);
      })

      setUser(null);
      navigate('/');
    }
  }
  React.useEffect(() => {
    logout();
  })
  return <></>;
};

export default Logout;
