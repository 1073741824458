import * as React from "react";
import { NavLink } from "react-router-dom";
import { BreadCrumps } from "../../types/BreadCrumps";

interface IBreadCrumbsProps {
  breadcrumbs: Array<BreadCrumps>;
}

const BreadCrumbs: React.FunctionComponent<IBreadCrumbsProps> = (props) => {
  const { breadcrumbs } = props;
  const lastIndex = breadcrumbs.length - 1;
  return (
    <div className="main-breadcrumbs">
      {breadcrumbs.map((link, index) => (
        <NavLink to={link.link ? link.link : ''} key={index}>
          {link.descr && link.descr.charAt(0).toUpperCase() + link.descr.slice(1)}
          {index !== lastIndex ? " / " : null}
        </NavLink>
      ))}
    </div>
  );
};

export default BreadCrumbs;
