import * as React from 'react';
import SelectComponent from '../../../select/SelectComponent';
import { AdvSearchFields } from '../../../../types/emuns/adfSearchFields';
import { useFormikContext } from 'formik';
import { IAdvSearchForm } from '../../../../types/data/advSearchForm';
import { IStatus } from '../../../../types/data/AdvListConfig';

interface IAdvListStatusProps {
    config: IStatus[];
    label: string;
    defaultTransl: string;
}

const AdvListStatus: React.FunctionComponent<IAdvListStatusProps> = (props) => {
    const { config, label, defaultTransl } = props;
    const { setFieldValue } =
        useFormikContext<IAdvSearchForm>();
    const changeHandler = (value: string | string[]) => {
        setFieldValue(AdvSearchFields.STATUS, value)
    }
    const statuses = [{ label: defaultTransl, value: '0' }].concat(config.map((el) => { return { label: el.name, value: el.status_id.toString() } }))
    return <div className='adv-list-form-field'>
        <p className='adv-list-form-field-label'>{label}</p>
        <SelectComponent
            name={AdvSearchFields.STATUS}
            spaceWidth={100}
            selectWidth={100}
            options={statuses}
            onChange={changeHandler} />
    </div>
};

export default React.memo(AdvListStatus);
