import * as React from 'react';
import { BASE_PATH } from '../../api/axios';

interface IImageFromDataProps {
    image?: string;
}

const ImageFromData: React.FunctionComponent<IImageFromDataProps> = (props) => {
    const [image, setImage] = React.useState<string | undefined>(undefined)
    React.useEffect(() => {
        if (!props.image) {
            return
        }
        setImage(props.image)
    }, [props.image])
    return <>
        {image &&
            < img src={image} alt="" className="img-logo" />

        }
    </>
};

export default ImageFromData;
