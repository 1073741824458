import * as React from "react";
import { IFilters } from "../../../../types/responses/translations/advertisementTranslations";
import { IFiltersConfig } from "../../../../types/data/advConfig";
import CheckboxInput from "../../../inputs/CheckboxInput";
import "./filters.scss";
import { AdvFields } from "../../../../types/emuns/advfields";
import FilterCheckboxInput from "../../../inputs/FilterCheckboxInput";
interface IFilertModuleProps {
  translations: IFilters;
  config: {
    [x: string]: IFiltersConfig;
  };
}

const FilertModule: React.FunctionComponent<IFilertModuleProps> = (props) => {
  const { translations, config } = props;
  return (
    <div>
      <h2 className="title">{translations.title}</h2>
      {Object.keys(config).map((filterObj, i) => {
        return (
          <div key={i} className="filters-wrapper">
            <h3 className="sub-title">{config[filterObj].title}</h3>
            <div className="filters-section">
              {config[filterObj].filters.map((filter, index) => {
                return (
                  <div className="filters-checkbx-wrapper" key={index}>
                    <FilterCheckboxInput
                      name={`${filter.id}`}
                      label={filter.name}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilertModule;
