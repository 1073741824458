import * as React from 'react';
import { Form } from './Form';
import { IAdvSearchForm } from '../../types/data/advSearchForm';
import { advSearchInitialValues } from '../../validation/advSearchField/advSearshField';
import { AdvSearchFields } from '../../types/emuns/adfSearchFields';
import AdvListStatus from '../content/AdvList/selectcomponent/AdvListStatus';
import { IAdvListConfig } from '../../types/data/AdvListConfig';
import AdvListCountry from '../content/AdvList/selectcomponent/AdvListCountry';
import AdvListCurrency from '../content/AdvList/selectcomponent/AdvListCurrency';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import AdvListType from '../content/AdvList/selectcomponent/AdvListType';
import AdvListResponcePerson from '../content/AdvList/selectcomponent/AdvListResponcePerson';
import PriceInput from '../content/AdvList/listinputs/PriceInput';
import NumberInputWithUnitDouble from '../inputs/NumberInputWithUnitDouble';
import NumberInput from '../inputs/NumberInput';
import { StoreContext } from '../../store/StoreProvider';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';

interface IAdvSearchFormProps {
    innerRef: React.MutableRefObject<any>
    config: IAdvListConfig
    translations: IFormTranslations;
    onClick: (values: IAdvSearchForm) => void
}

const AdvSearchForm: React.FunctionComponent<IAdvSearchFormProps> = (props) => {
    const { innerRef, config, translations, onClick } = props
    const { store } = React.useContext(StoreContext);

    const [visible, setVisible] = React.useState(false);
    const clickHandler = () => {
        setVisible(!visible)
    }
    const submitHandler = ((values: IAdvSearchForm) => {
        onClick(values)
    })
    return <div className=''>
        {!visible &&
            <div className="filter-show-btn" onClick={clickHandler}>
                <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
            </div>
        }
        <div className={`search-form-wrapper ${visible ? '' : 'hiden'}`}>
            <Form<IAdvSearchForm>
                schema={{
                    initialValues: advSearchInitialValues,
                    onSubmit: submitHandler,
                }}
                enableReinitialize
                innerRef={innerRef}
            >
                <div className={`search-form`}>
                    <h2>{translations.titleObjects} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
                    <div className="search-form-inner">
                        <AdvListStatus config={config.statuses} label={translations.status_label} defaultTransl={translations.all_placeholder} />
                        <AdvListCountry config={config.countries} label={translations.country_label} defaultTransl={translations.all_placeholder} />
                        <AdvListCurrency label={translations.currency_label} />
                        <PriceInput label={translations.price_label} />
                        {
                            store.user?.role !== 'private seller' &&
                            <AdvListResponcePerson label={translations.responcible_label} />
                        }
                        <AdvListType config={config.types} label={translations.property_label} defaultTransl={translations.all_placeholder} />
                        <NumberInputWithUnitDouble
                            label={translations.areatotal_label}
                            nameFirst={AdvSearchFields.TOTAL_AREA_MIN}
                            nameSecond={AdvSearchFields.TOTAL_AREA_MAX}
                            unitName={AdvSearchFields.TOTAL_AREA_UNIT}
                            phFirst="MIN"
                            phSecond="MAX"
                            area
                            className='adv-list-form-field' />
                        <NumberInputWithUnitDouble
                            label={translations.landsize_label}
                            nameFirst={AdvSearchFields.LAND_SIZE_MIN}
                            nameSecond={AdvSearchFields.LAND_SIZE_MAX}
                            unitName={AdvSearchFields.LAND_SIZE_UNIT}
                            phFirst="MIN"
                            phSecond="MAX"
                            area
                            className='adv-list-form-field' />
                        <NumberInput name={AdvSearchFields.ROOMS} label={translations.rooms_label} placeholder='amount of rooms' className='adv-list-form-field' />
                        <AdvListPagination label={translations.pagination_label} field={AdvSearchFields.PER_PAGE} />
                    </div>
                </div>
                <div className='btns-wrapper'>
                    <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
                    <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
                </div>
            </Form>
        </div>
    </div>
};

export default AdvSearchForm;
