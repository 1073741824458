import * as React from "react";
import './submitButton.scss'
interface ISumbitButtonProps {
  text: string;
  className?: string;
  onClick: () => void;
}

const SumbitButton: React.FunctionComponent<ISumbitButtonProps> = (props) => {
  const { text, className, onClick } = props;
  return (
    <button type="button" onClick={onClick} className={`btn-submit ${className ?? ""}`}>
      {text}
    </button>
  );
};

export default SumbitButton;
