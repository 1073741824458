import * as React from "react";
import ArrayMove from "array-move";
import { IPhoto } from "../../../../types/responses/translations/advertisementTranslations";
import { useFormikContext } from "formik";
import { IAdvInitialValues } from "../../../../types/data/advInitialValues";
import { checkImage } from "../../../../utils/ckeckExtentions";
import DropPhotoComponent from "../DropPhotoComponent";
import { ApiImageDestination } from "../../../../types/emuns/ImageDestinaionEnum";
import { AdvFields } from "../../../../types/emuns/advfields";
interface IPhotoModuleProps {
  translations: IPhoto;
  deleteImage: (
    image: string,
    destination: ApiImageDestination
  ) => Promise<void>;
  loadImageOnServer: (
    file: File,
    destination: ApiImageDestination
  ) => Promise<any>;
}

const PhotoModule: React.FunctionComponent<IPhotoModuleProps> = (props) => {
  const { translations, deleteImage, loadImageOnServer } = props;
  const {
    values,
    errors,
    setValues,
    setErrors,
    touched,
    setTouched,
    setFieldValue,
    setFieldError
  } = useFormikContext<IAdvInitialValues>();
  const [images, setImages] = React.useState<string[] | null>(null);
  const [error, setError] = React.useState<string>();
  const onLoad = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      setError("");
      const validated = checkImage(files[0], {
        size: "alowed size 10 MB",
        ext: 'alowed extentions "jpeg", "jpg", "webp", "svg"',
      });
      if (validated) {
        setError(validated);
        setErrors({ ...errors, images: validated });
        return;
      }
      const image = await loadImageOnServer(
        files[0],
        ApiImageDestination.IMAGES
      );
      if (images) {
        setFieldValue(AdvFields.IMAGES, [...images, image], false);
        setImages([...images, image]);
        setTouched({ ...touched, [AdvFields.IMAGES]: true }, false);
        setFieldError(AdvFields.IMAGES, undefined);
      } else {
        setFieldValue(AdvFields.IMAGES, [image], false);
        setImages([image]);
        setTouched({ ...touched, [AdvFields.IMAGES]: true }, false);
        setFieldError(AdvFields.IMAGES, undefined);
      }
    }
  };
  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    if (!event.dataTransfer) {
      return;
    }
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const array = Array.from(droppedFiles);
      let mutable: string[] = [];
      for (let i = 0; i < array.length; i++) {
        setError("");
        const validated = checkImage(array[i], {
          size: "alowed size 10 MB",
          ext: 'alowed extentions "jpeg", "jpg", "webp", "svg"',
        });
        if (validated) {
          setError(validated);
          setErrors({ ...errors, images: validated });
          return;
        }
        if (images && (images.length < 15)) {
          const file = array[i];
          const res = await loadImageOnServer(file, ApiImageDestination.IMAGES);
          mutable = [...mutable, res];
        }
      }
      if (images) {
        const imageValues = [...images, ...mutable];
        setImages(imageValues);
        await setValues({ ...values, images: imageValues });
      } else {
        const imageValues = [...mutable];
        setImages(imageValues);
        await setValues({ ...values, images: imageValues });
      }

    }
  };
  const sort = (images: string[], oldIndex: number, newIndex: number) => {
    return ArrayMove(images, oldIndex, newIndex)
  }
  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    if (images) {
      const sortResult = sort(images, oldIndex, newIndex);
      setValues({ ...values, images: sortResult });
      setImages(sortResult);
    }

  };
  const deleteHandler = async (i: number) => {
    if (images) {
      const newGalery = [...images];
      const deletedImage = newGalery.splice(i, 1);
      await deleteImage(deletedImage[0], ApiImageDestination.IMAGES);
      setImages(newGalery);
    }

  };
  React.useEffect(() => {
    if (values.images.length < 1) {
      setImages(null)
    } else {
      setImages(values.images)
    }
  }, [values.images])
  return (
    <DropPhotoComponent
      translations={translations}
      error={touched.images ? errors.images : ""}
      images={images}
      deleteImage={deleteHandler}
      onLoad={onLoad}
      handleDrop={handleDrop}
      onSortEnd={onSortEnd}
      hasPrewiew
    />
  );
};

export default PhotoModule;
