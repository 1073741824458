import { FormikValues } from "formik";
import * as React from "react";
import { IProfileTranslationData } from "../../types/responses/translations/profileTranslations";
import PhotoModule from "../content/profile/photo/PhotoModule";
import PersonalData from "../content/profile/personaldata/PesronalData";
import TimeZone from "../content/profile/timezone/TimeZone";
import { Form } from "./Form";
import {
  profileInitialValues,
  profileSchema,
} from "../../validation/profile/profileShema";
import { IProfileInitialValues } from "../../types/data/ProfileInitialValues";
import ContactDetails from "../content/profile/ContractDetails/ContactDetails";
import { StoreContext } from "../../store/StoreProvider";

interface IProfileFormProps {
  inderRef: React.MutableRefObject<any>
  translations: IProfileTranslationData;
  initialValues?: IProfileInitialValues;
  deleteImage: () => Promise<true | undefined>
  loadImage: (file: File) => Promise<any>
  getUser: () => Promise<any>
  onSubmit: (data: FormikValues) => Promise<void>
}

const ProfileForm: React.FunctionComponent<IProfileFormProps> = (props) => {

  const { translations, initialValues, inderRef, deleteImage, loadImage, getUser, onSubmit } = props
  const { store, setUser } = React.useContext(StoreContext);
  const [initial, setInitial] = React.useState<IProfileInitialValues>();

  const submitWrapper = async (values: FormikValues) => {
    await onSubmit(values)
    if (store.lang === store.updated_lang) {
      const res = await getUser();
      setUser(res);

    }
  }
  const onSubmitHandler = (values: FormikValues): void | Promise<any> => {
    submitWrapper(values)

  };
  React.useEffect(() => {
    setInitial(initialValues)
  }, [initialValues])
  return (
    <Form<IProfileInitialValues>
      schema={{
          initialValues: initial ? initial : profileInitialValues,
          onSubmit: onSubmitHandler,
          validationSchema: profileSchema,
        }
        }
        enableReinitialize
        innerRef={inderRef}
      ><></>
      <PhotoModule translations={translations.photo} deleteImage={deleteImage} loadImage={loadImage} />
      <PersonalData
        translations={translations.personal_data}
      />
      <ContactDetails
        translations={translations.contact_details}
      />
      <TimeZone translations={translations.timezone} />
    </Form>
  );
};

export default ProfileForm;

