import * as React from 'react';
import './index.scss';
interface IDeleteButtonProps {
    name: string;
    onClick: () => void
}

const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = (props) => {
    const { name, onClick } = props
    return <div onClick={onClick} className='delete-btn'>
        <i className="fa-regular fa-trash-can"></i>
        <p className='delete-btn-text'>{name}</p>
    </div>;
};

export default DeleteButton;
