import * as React from "react";
import "./index.scss";
import ImageFromData from "./ImageFromData";

interface IImageProps {
  image?: string | ArrayBuffer | null;
  imageSrc?: string;
}

const Image: React.FunctionComponent<IImageProps> = (props) => {
  const { imageSrc, image } = props;
  const [previewImage, setPreviewImage] = React.useState<string>();
  React.useEffect(() => {
    if (!image) {
      return;
    }
    setPreviewImage(image as string);
  }, [image]);
  return (
    <div className="img-wrapper">
      {previewImage ? (
        <img src={previewImage} alt="" className="img-logo" />
      ) : (
        <ImageFromData image={imageSrc} />
      )}
    </div>
  );
};

export default Image;
