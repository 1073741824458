import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { StoreContext } from "../store/StoreProvider";
import { IHeader, IFooter } from "../types/responses/translations";
import { sortMenuTranslations } from "../utils/sortMenuTranslations";
import { useLayoutTranslations } from "../hooks/useLayoutTranslations";
import { ISocialMenu } from "../types/responses/layoutResponce";
import Loader from "../components/loader/Loader";

interface ILayoutProps {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<ILayoutProps> = (props) => {
  const {
    setLang,
    setUpdatedLang,
    setUser,
    setLeftSidebar,
    setLangIschanged,
    setLangs,
    setCurrencies,
    setCurrencyIsChanged,
    setCurrency
  } = React.useContext(StoreContext);
  const [headerMenu, setHeaderMenu] = React.useState(Array<IHeader>);
  const [footerMenu, setFooterMenu] = React.useState(Array<IFooter>);
  const [socialMenu, setSocialMenu] = React.useState<Array<ISocialMenu>>();
  const { isLoading, data } = useLayoutTranslations();
  React.useEffect(() => {
    if (data) {
      const sorted = sortMenuTranslations(data.menu);
      setLeftSidebar(sorted.sidebar);
      setHeaderMenu(sorted.header);
      setFooterMenu(sorted.footer);
      setLang(data.user.lang);
      setUpdatedLang(data.user.lang);
      setUser({ ...data.user, langs: JSON.parse(data.user?.langs) });
      setLangs(data.langs);
      setSocialMenu(data.social);
      setLangIschanged(false);
      setCurrencyIsChanged(false);
      setCurrency(data.user.cur)
      setCurrencies(data.currencies)
    }
  }, [data]);
  return (
    <>
      {headerMenu && <Header menu={headerMenu} />}
      {props.children}
      {footerMenu && socialMenu && <Footer menu={footerMenu} social={socialMenu} />}
      {isLoading && <Loader text={"Loading"} />}
    </>
  );
};

export default React.memo(Layout);
