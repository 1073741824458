import * as React from 'react';
import Main from '../layouts/Main';
import Pagination from '../components/pagination/Pagination';
import Loader from '../components/loader/Loader';
import { useRequestsData } from '../hooks/useRequestsData';
import { IRequestsForm } from '../types/data/requestsForm';
import { FormikProps, FormikValues } from 'formik';
import { RequestsFields } from '../types/emuns/requestsFields';
import RequestsSearchForm from '../components/forms/RequestsSearchForm';
import ControlPanel from '../components/content/applications/ControlPanel';
import RequestsList from '../components/content/applications/RequestsList';
import { Modal } from 'antd';
import RequestsCard from '../components/card/RequestsCard';
import RequestsModalReplyCard from '../components/card/RequestsModalReplyCard';
import { IReplyForm } from '../validation/replyShema/replyShema';

interface IRequestsProps {
}

const Requests: React.FunctionComponent<IRequestsProps> = (props) => {
    const { isLoading, data, mainTranslations, applicationTranslations, pagination, config, submit, replySubmit, toArchive } = useRequestsData()
    const innerRef = React.useRef<FormikProps<IRequestsForm>>();
    const replyRef = React.useRef<FormikProps<IReplyForm>>();
    const [target, setTarget] = React.useState("active");
    const [modaiIsOpen, setModalIsOpen] = React.useState(false);
    const [cardIndex, setCardIndex] = React.useState(-1);
    const previewHandler = (index: number) => {
        setModalIsOpen(true)
        setCardIndex(index)
    }
    const onModalClose = () => {
        setModalIsOpen(false)
        setCardIndex(-1);
    }
    const onArchive = async (index: number) => {
        setModalIsOpen(false)
        const res = await toArchive(index)
        if (res) {
            innerRef.current?.submitForm();
        }
    }
    const onReplySubmit = async (index: number) => {
        await replyRef.current?.setFieldValue('id', index)
        const res = await replyRef.current?.submitForm()
        if (res) {
            innerRef.current?.submitForm();
        }

    }
    const changeHandler = async (status: string) => {
        await innerRef.current?.setFieldValue(RequestsFields.STATUS, status);
        setTarget(status);
        setTimeout(() => {
            innerRef.current?.submitForm();
        }, 0);
    };
    
    return (
      <Main
        title={mainTranslations?.titleRequests}
        breadcrumbs={[
          {
            link: "/account",
            descr: mainTranslations?.breadcrumbs.home,
          },
          {
            link: "/account/profile",
            descr: mainTranslations?.breadcrumbs.profile,
          },
          {
            link: "/account/Requests",
            descr: mainTranslations?.breadcrumbs.requests,
          },
        ]}
      >
        <div className="adv-list-content">
          <div className="control-panel-wrapper">
            {applicationTranslations && config && (
              <RequestsSearchForm
                innerRef={innerRef}
                config={config}
                translations={applicationTranslations.form}
                onClick={submit}
              />
            )}
            {applicationTranslations && config && (
              <ControlPanel
                translations={applicationTranslations.list}
                onChange={changeHandler}
                target={target}
              />
            )}
          </div>
          <div className="card-wrapper">
            {applicationTranslations && (
              <RequestsList
                data={data}
                translations={applicationTranslations.list}
                toArchive={onArchive}
                preview={previewHandler}
              />
            )}
          </div>
          {pagination.current.data.last_page > 1 && (
            <Pagination
              pagination={pagination}
              params={innerRef.current?.values}
            />
          )}
          {isLoading && <Loader text={"Loading"} />}
        </div>
        {data && applicationTranslations && (
          <Modal
            open={modaiIsOpen}
            onCancel={() => setModalIsOpen(false)}
            width={"70%"}
            footer={null}
            title={applicationTranslations.list.modal_label}
          >
            <RequestsCard
              card={data[cardIndex]}
              child={
                <RequestsModalReplyCard
                  card={data[cardIndex]}
                  translations={applicationTranslations.list}
                  onClose={onModalClose}
                  onArchive={onArchive}
                  submitFn={replySubmit}
                  onSubmit={onReplySubmit}
                  innerRef={replyRef}
                />
              }
            />
          </Modal>
        )}
      </Main>
    );
};

export default Requests;
