import axios from "axios";
import { useContext, useState } from "react";
import { BASE_PATH } from "../api/axios";
import { IAdvTranslatonsResponce } from "../types/responses/translations/advertisementTranslations";
import { IAdvInitialValues } from "../types/data/advInitialValues";
import { IAdvConfig } from "../types/data/advConfig";
import { ApiImageDestination } from "../types/emuns/ImageDestinaionEnum";
import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { SystemMessageContext } from "../store/SystemMessageProvider";
import { SystemMessage } from "../types/emuns/systemMessage";

export const useAdvertisementData = () => {
  const { openNotificationWindow } = useContext(SystemMessageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [advTranslations, setAdvTranslations] =
    useState<IAdvTranslatonsResponce>();
  const navigate = useNavigate();
  const [data, setData] = useState<IAdvInitialValues | null>(null);
  const [config, setConfig] = useState<IAdvConfig>();
  const [modal, setModal] = useState(false);
  //get translations
  const getAdvTranslations = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        BASE_PATH + "/api/advertisement/get/translations"
      );
      setAdvTranslations(res.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  //load adv data
  const getAdvData = async (id: string | null = null) => {
    const path = id
      ? BASE_PATH + `/api/advertisement/getbyid/${id}`
      : BASE_PATH + "/api/advertisement/get/temp";
    try {
      setIsLoading(true);
      const res = await axios.get(path);
      if (res.data.advertisement) {
        setData({
          ...res.data.advertisement,
          phones: JSON.parse(res.data.advertisement.phones) ?? null,
          points_of_interest:
            JSON.parse(res.data.advertisement.points_of_interest) ?? null,
        });
      } else setData(null);
      setConfig(res.data.config);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = async (id: string | null = null) => {
    if (!id) {
      setData(null);
    }
    getAdvTranslations();
    getAdvData(id);
  };
  const loadMultiData = async (value: string, id: string | null = null) => {
    const url =
      BASE_PATH +
      (id
        ? "/api/advertisement/get/multi-data"
        : "/api/advertisement/get/temp/multi-data");
    try {
      if (value) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("lang", value);
        if (id) {
          formData.append("id", id);
        }
        const res = await axios.post(url, formData);
        setIsLoading(false);
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const onSubmit = async (data: FormikValues, id: string | null = null) => {
    const path = id
      ? BASE_PATH + `/api/advertisement/edit`
      : BASE_PATH + "/api/advertisement/ceate";
    try {
      setIsLoading(true);
      const formData = new FormData();
      const {
        images,
        flor_plan,
        description,
        city,
        district,
        address,
        filters,
        updated_lang,
        ...rest
      } = data;

      formData.append("temp", JSON.stringify(rest));
      formData.append(
        "other",
        JSON.stringify({
          images,
          flor_plan,
          filters,
          updated_lang,
        })
      );
      formData.append(
        "translations",
        JSON.stringify({ description, city, address, district })
      );
      if (id) {
        formData.append("id", id);
      }
      const res = await axios.post(path, formData);
      if (res.status === 201) {
        if (rest.type === "rent") {
          navigate("/account/announcements/rent");
        }
        if (rest.type === "sell") {
          navigate("/account/announcements/sell");
        }
      }
    } catch (error) {
      openNotificationWindow(SystemMessage.ERROR, 'Error', 'Somthing went wrong')
      
      console.log(error);
      setIsLoading(false);
    } finally{
      setIsLoading(false);
      if (id) {
        openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Advertisement updated successfuly')
      }
    }
  };

  //load images on server
  const loadImageOnServer = async (
    file: File,
    destination: ApiImageDestination,
    id: string | null = null
  ) => {
    const images = id
      ? BASE_PATH + `/api/advertisement/images/create`
      : BASE_PATH + "/api/advertisement/images/temp/images/create";
    const flor_plan = id
      ? BASE_PATH + `/api/advertisement/images/floorplan/create`
      : BASE_PATH + "/api/advertisement/images/temp/floorplan/create";
    const url = destination === ApiImageDestination.IMAGES ? images : flor_plan;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      if (id) {
        formData.append("id", id);
      }
      const { data } = await axios.post(url, formData);
      
      return data;
    } catch (error) {
      openNotificationWindow(SystemMessage.ERROR, 'Error', 'Somthing went wrong')
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Image update successfuly')
    }
  };
  const deleteImage = async (
    image: string,
    destination: ApiImageDestination,
    id: string | null = null
  ) => {
    const images = id
      ? "/api/advertisement/images/delete"
      : "/api/advertisement/images/temp/images/delete";
    const flor_plan = id
      ? "/api/advertisement/images/floorplan/delete"
      : "/api/advertisement/images/temp/floorplan/delete";
    const url = destination === ApiImageDestination.IMAGES ? images : flor_plan;
    try {
      setIsLoading(true);
      await axios.post(BASE_PATH + url, {
        image: image,
        id: id,
      });
    } catch (error) {
      setIsLoading(false);
      openNotificationWindow(SystemMessage.ERROR, 'Error', 'Somthing went wrong')
    } finally {
      setIsLoading(false);
      openNotificationWindow(SystemMessage.SUCCESS, 'Success', 'Image delete successfuly')
    }
  };
  return {
    isLoading,
    advTranslations,
    data,
    config,
    loadImageOnServer,
    deleteImage,
    onSubmit,
    loadData,
    getAdvData,
    loadMultiData,
  };
};
