import React from "react";
import LeftSidebar from "../components/navbars/leftsidebar/LeftSidebar";
import BreadCrumbs from "../components/breadcrumbs/BreadCrumbs";
import { BreadCrumps } from "../types/BreadCrumps";

interface IMainProps {
  children: React.ReactNode;
  title?: string;
  breadcrumbs: Array<BreadCrumps>;
}

const Main: React.FunctionComponent<IMainProps> = (props) => {
  return (
    <main className="main content">
      <div className="container">
        <div className="main-title-wrapper">
          <h2 className="main-title">{props.title && props.title}</h2>
          <BreadCrumbs breadcrumbs={props.breadcrumbs} />
        </div>
        <div className="main-inner">
          <LeftSidebar />
          {props.children}
        </div>
      </div>
    </main>
  );
};

export default React.memo(Main);
