import { useField } from "formik";
import * as React from "react";
import Unit from "../unit/Unit";
import { AdvFields } from "../../types/emuns/advfields";

interface INumberInputWithUnitProps {
  label?: string;
  name: string;
  withError?: true;
  id?: string;
  image?: string;
  placeholder?: string;
  unitValue?: string;
  unitName: string;
  className?: string;
  area?: true;
}

const NumberInputWithUnit: React.FunctionComponent<
  INumberInputWithUnitProps
> = (props) => {
  const { label, name, image, unitName, withError, area, placeholder, className } = props;
  const [field, meta] = useField(props.name);
  return (
    <div className={`number-input-with-unit ${className ?? ''} ${withError ? "with-error" : ""}`}>
      <label className={`label`}>
        <div className="label-wrapper">
          <span className="label-text">{label}</span>{" "}
          {image && <img src={image} alt="" className="label-image" />}
        </div>
        <div className="input-wrapper">
          <input
            className="input"
            type="number"
            name={name}
            value={field.value}
            onChange={field.onChange}
            placeholder={placeholder}
          />
        </div>
      </label>
      <Unit name={unitName} area={area} />
      <div className="unit-error error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </div>
  );
};

export default NumberInputWithUnit;
