import * as React from "react";
import AdvListCard from "../../card/AdvListCard";
import { IAdvListTranslations } from "../../../types/responses/translations/advListTranslations";
import EmptyList from "./EmptyList";
import { IListCard } from "../../../types/data/requestsData";

interface IAdvListProps {
  data?: IListCard[];
  translations: IAdvListTranslations;
  status: string;
  copyFn: (id: number) => void;
  deleteFn: (id: number) => void;
  publish: (id: number) => Promise<void>;
}

const AdvList: React.FunctionComponent<IAdvListProps> = (props) => {
  const { data, translations, status, copyFn, deleteFn, publish } =
    props;
  return (
    <>
      {data && data?.length > 0 ? (
        data.map((card, index) => (
          <AdvListCard
            copyFn={copyFn}
            deleteFn={deleteFn}
            card={card}
            key={index}
            translations={translations}
            status={status}
            publish={publish}
          />
        ))
      ) : (
        <EmptyList text={translations.empty_list} />
      )}
    </>
  );
};

export default AdvList;
