import * as React from "react";
import { IDescription } from "../../../../types/responses/translations/advertisementTranslations";
import TextArea from "../../../inputs/TextArea";
import { AdvFields } from "../../../../types/emuns/advfields";

interface IDescriptionModuleProps {
  translations: IDescription;
  image?: string;
}

const DescriptionModule: React.FunctionComponent<IDescriptionModuleProps> = (
  props
) => {
  const { translations, image } = props;
  return (
    <div className="description-module module">
      <h2>{translations.title}</h2>
      <TextArea name={AdvFields.DESCRIPTION} label={translations.label} image={image} />
    </div>
  );
};

export default DescriptionModule;
