import * as React from "react";
import "./ListCard.scss";
import { butilizePrice } from "../../utils/buytilyzePrice";
import { IListCard } from "../../types/data/requestsData";
import AdvCard from "./AdvCard";
interface IRequestsCardProps {
    card: IListCard;
    child: React.ReactNode;
}

const RequestsCard: React.FunctionComponent<IRequestsCardProps> = (props) => {
    const { card, child } = props;
    const [data, setData] = React.useState("");
    const [price, setPrice] = React.useState("");
    React.useEffect(() => {
        if (!card.price) {
            return;
        }
        const price = butilizePrice(card.price);
        setPrice(price);
    }, [card.price]);
    React.useEffect(() => {
        if (!card.created_at) {
            return;
        }
        const format = card.created_at.split("T")[0];
        setData(format);
    }, [card.created_at]);
    return (
        <div className="applications-list-card">
            <AdvCard card={card} data={data} price={price} />
            {child}
        </div>
    );
};

export default RequestsCard;