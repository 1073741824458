import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
} from "formik";
import { CSSProperties, ReactNode } from "react";

type FormikConsumerFunction<T> = (props: FormikProps<T>) => React.ReactNode;

export interface Props<T> {
  children: ReactNode | ReactNode[] | FormikConsumerFunction<T>;
  schema: {
    initialValues: FormikConfig<T>["initialValues"];
    onSubmit: FormikConfig<T>["onSubmit"];
    onReset?: FormikConfig<T>["onReset"];
    validationSchema?: any;
    onValidationChanged?: (isValid: boolean, values: T) => void;
  };
  enableReinitialize?: FormikConfig<T>["enableReinitialize"];
  innerRef?: FormikConfig<T>["innerRef"]; // if submit btn is outside of the form or for getting formik validation object
  formStyle?: CSSProperties;
  "data-testid"?: string;
}

export function Form<T extends FormikValues>(props: Props<T>) {
  const {
    schema: {
      onValidationChanged,
      initialValues,
      onSubmit,
      onReset,
      validationSchema,
    },
    enableReinitialize,
    innerRef,
    children,
    formStyle,
  } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}

      onSubmit={onSubmit}
      validate={(values: T) => {
        if (!validationSchema || !onValidationChanged) return;
        validationSchema
          .validate(values)
          .then(() => onValidationChanged(true, values))
          .catch(() => onValidationChanged(false, values));
      }}
      onReset={onReset}
      enableReinitialize={enableReinitialize}
      innerRef={innerRef}
    >
      {(formikProps) => (
        <FormikForm style={formStyle}>
          {typeof children === "function"
            ? // For using context props in the same component as Form
            // Usage example: <Form>({ setFieldValue, values }) => <SomeComponent...
            children(formikProps)
            : children}
        </FormikForm>
      )}
    </Formik>
  );
}
