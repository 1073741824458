import * as React from 'react';
import InputWithCheckbox from '../../../inputs/InputWithCheckbox';
import { IContactDetails } from '../../../../types/responses/translations/advertisementTranslations';
import { useFormikContext } from 'formik';
import { IAdvInitialValues } from '../../../../types/data/advInitialValues';
import { AdvFields } from '../../../../types/emuns/advfields';
interface IContractDetailsProps {
    translations: IContactDetails
}

const ContractDetails: React.FunctionComponent<IContractDetailsProps> = (props) => {
    const { values, errors, setValues } = useFormikContext<IAdvInitialValues>();
    const { translations } = props;
    const addPhone = () => {
        const phones = [...values.phones, { phone: '', visible: false }]
        setValues({ ...values, phones });
    }
    const removePhone = (index: number) => () => {
        const phones = [...values.phones]
        phones.splice(index, 1);
        setValues({ ...values, phones });
    }
    React.useEffect(() => {
        if (values.phones.length < 1) {
            const phones = [...values.phones, { phone: '', visible: false }]
            setValues({ ...values, phones });
        }
    }, [setValues, values, values.phones.length])
    return <div className='contacts module'>
        <h2 className='contacts-title title'>{translations.title}</h2>
        <div className='contacts-inputs'>
            <p>{translations.label}</p>
            {values.phones.length < 1 &&
                <div>
                    {
                        errors && errors.phones && (<span className='error_msg'>{'Need to pass at least one phone number'}</span>)
                    }
                </div>
            }
            {
                values.phones && values.phones.map((el, i) => {
                    return (
                      <div key={i} className="input-checkbox-wrapper">
                        <InputWithCheckbox
                          name={`${AdvFields.PHONES}[${i}].phone`}
                          type={"tel"}
                          placeholder={translations.placeholder}
                          onDelete={removePhone(i)}
                          showDeleteBtn={
                            values.phones.length < 2 ? false : true
                          }
                          withError
                          checkboxLabel={translations.checkbox}
                          checkboxName={`${AdvFields.PHONES}[${i}].visible`}
                          defaultChecked={el.visible}
                          inputImage={
                            <i className="fa-solid fa-mobile-screen-button"></i>
                          }
                        />
                      </div>
                    );
                })
            }

            <div className='contacts-add-btn-wrapper' onClick={addPhone}>
                <i className="fa-solid fa-plus"></i>
                <span className='contacts-add-btn'>{'add phone'}</span>
            </div>
        </div>
    </div>;
};

export default ContractDetails;
