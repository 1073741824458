import Layout from "./layouts/Layout";
import "./sass/app.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import PersonalArea from "./pages/PersonalArea";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Logout from "./pages/Logout";
import Advertisement from "./pages/Advertisement";
import AdvertisemetList from "./pages/AdvertisementList";
import SystemMessage from "./components/modals/SystemMessage";
import Requests from "./pages/Requests";
import EmptyPage from "./pages/EmptyPage";
import FavoritesList from "./pages/FavoritesList";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/account/*"
          element={
            <Layout>
              <Routes>
                <Route path="/profile" element={<PersonalArea />} />
                <Route
                  path="/anoucements/create-ad"
                  element={<Advertisement />}
                />
                <Route
                  path="/anoucements/:id/edit-ad"
                  element={<Advertisement />}
                />
                <Route
                  path="/announcements/:type"
                  element={<AdvertisemetList />}
                />
                <Route path="/requests" element={<Requests />} />
                <Route path="/favorites" element={<FavoritesList />} />
                <Route path="/servises" element={<EmptyPage />} />
                <Route path="/instructions" element={<EmptyPage />} />
                <Route path="/privacy" element={<EmptyPage />} />
                <Route path="/public-contract" element={<EmptyPage />} />
                <Route path="/settings" element={<EmptyPage />} />
              </Routes>
              <SystemMessage />
            </Layout>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to={'/account/profile'} replace />} />
        {/* <Route path="*" element={<Navigate to={'/login'} replace />} /> */}
      </Routes>
    </div>
  );
}

export default App;
