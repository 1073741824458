import { useField } from "formik";
import * as React from "react";
import {
  BtnBold,
  BtnItalic,
  BtnNumberedList,
  BtnUndo,
  BtnRedo,
  BtnClearFormatting,
  BtnBulletList,
  BtnStyles,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
interface ITextAreaProps {
  name: string;
  label?: string;
  className?: string;
  image?: string;
}

const TextArea: React.FunctionComponent<ITextAreaProps> = (props) => {
  const { name, label, className, image } = props;
  const [field, meta] = useField(name);
  return (
    <div className={`text-area-wrapper ${className ?? ""}`}>
      <p>
        {label} {image ? <img src={image} alt="" /> : ""}
      </p>
      {meta.touched && meta.error && (
        <span className="error_msg">{meta.error}</span>
      )}
      <EditorProvider>
        <Editor value={field.value} onChange={field.onChange} name={name}>
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnStyles />
            <BtnNumberedList />
            <BtnBulletList />
            <BtnClearFormatting />
            <BtnUndo />
            <BtnRedo />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </div>
  );
};

export default TextArea;
