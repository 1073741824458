import * as React from "react";
import { Tag } from "antd";
import { StoreContext } from "../../../../store/StoreProvider";
import TextInput from "../../../inputs/TextInput";
import "./index.scss";
import translate from "../../../../images/icons/legend/translate.png";
import { IPersonalDetailsTranlstion } from "../../../../types/responses/translations/profileTranslations";
import { useFormikContext } from "formik";
import { IProfileInitialValues } from "../../../../types/data/ProfileInitialValues";
import SelectComponent from "../../../select/SelectComponent";
interface IPersonalDataProps {
  translations: IPersonalDetailsTranlstion;
}

const PersonalData: React.FunctionComponent<IPersonalDataProps> = (props) => {
  const { values, setValues, touched, errors } = useFormikContext<IProfileInitialValues>();
  const onChange = (value: string | string[]
  ) => {
    setValues({ ...values, langs: value as string[] })
  };
  const { translations } = props;
  const { store } = React.useContext(StoreContext);
  const [langs, setLangs] = React.useState<
    Array<{ label: string; value: string }>
  >([]);
  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={"#67AECA"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 5,
          height: "25px",
        }}
      >
        {label}
      </Tag>
    );
  };
  React.useEffect(() => {
    if (store.langs) {
      const options: Array<{ label: string; value: string }> = [];
      store.langs.forEach((lang) =>
        options.push({ label: lang.name, value: lang.code })
      );
      setLangs(options);
    }
  }, [store.langs]);
  return (
    <div className="personal-data module">
      <h2 className="personal-data-title title">{translations.title}</h2>
      <div className="inputs-wrapper">
        <TextInput
          image={translate}
          name="name"
          label={translations.name}
          placeholder={translations.name_placeholder}
          inputImage={<i className="fa-regular fa-user"></i>}
        />
        <TextInput
          image={translate}
          name="last_name"
          label={translations.last_name}
          placeholder={translations.last_name_placeholder}
          inputImage={<i className="fa-regular fa-user"></i>}
        />
      </div>
      <div>
        <label htmlFor="langs-select" className="languges-label">
          {translations.languages}
        </label>
        {langs[0] && (
          <SelectComponent
            name="langs"
            value={ values.langs}
            onChange={onChange}
            spaceWidth={100}
            selectWidth={47}
            tag={tagRender}
            options={langs}
            mode='multiple'
            direction="vertical"
            placeholder={translations.languages}
          />
        )}
        {
          touched.langs && errors.langs && (<span className='error_msg'>{errors.langs}</span>)

        }
      </div>
    </div>
  );
};

export default PersonalData;
