import * as React from "react";
import "./ListCard.scss";
import { butilizePrice } from "../../utils/buytilyzePrice";
import EditButton from "../buttons/editButton/EditButton";
import { IAdvListTranslations } from "../../types/responses/translations/advListTranslations";
import CopyButton from "../buttons/copyBbtton/CopyButton";
import DeleteButton from "../buttons/deletebutton/DeleteButton";
import { useNavigate } from "react-router-dom";
import SumbitButton from "../buttons/submitButton/SubmitButton";
import AdvCard from "./AdvCard";
import { IListCard } from "../../types/data/requestsData";
interface IAdvListCardProps {
  card: IListCard;
  translations: IAdvListTranslations;
  status: string;
  copyFn: (id: number) => void;
  deleteFn: (id: number) => void;
  publish: (id: number) => Promise<void>;
}

const AdvListCard: React.FunctionComponent<IAdvListCardProps> = (props) => {
  const { card, translations, status, copyFn, deleteFn, publish } = props;
  const [data, setData] = React.useState("");
  const [price, setPrice] = React.useState("");
  const navigate = useNavigate();
  const editHandler = () => {
    navigate(`/account/anoucements/${card.id}/edit-ad`);
  };
  const copyHandler = () => {
    copyFn(card.id);
  };
  const deleteHandler = () => {
    deleteFn(card.id);
  };
  const publishHandler = () => {
    publish(card.id);
  };
  React.useEffect(() => {
    if (!card.price) {
      return;
    }
    const price = butilizePrice(card.price);
    setPrice(price);
  }, [card.price]);
  React.useEffect(() => {
    if (!card.created_at) {
      return;
    }
    const format = card.created_at.split("T")[0];
    setData(format);
  }, [card.created_at]);
  return (
    <div className="adv-list-card">
      <AdvCard card={card} data={data} price={price} button={<SumbitButton
        className={"publish-btn"}
        text={translations.publish_btn}
        onClick={publishHandler}
      />} />
      <div className="adv-list-card-tools">
        <div className="adv-list-card-tools-status">
          <p className="adv-list-card-tools-status--label">{status}</p>
          <p className="adv-list-card-tools-status--text">{card.status_name}</p>
        </div>
        <EditButton name={translations.edit_btn} onClick={editHandler} />
        <CopyButton name={translations.copy_btn} onClick={copyHandler} />
        <DeleteButton name={translations.delete_btn} onClick={deleteHandler} />
      </div>
    </div>
  );
};

export default AdvListCard;
