import * as React from 'react';
import { Form } from './Form';
import { IFavoritesForm } from '../../types/data/favoritesForm';
import { favoritesInitialValues } from '../../validation/favoritesFields/favoritesForm';
import { IFormTranslations } from '../../types/responses/translations/formTranslations';
import { IFavoritesConfig } from '../../types/data/favoretesConfig';
import FavoritesPropertySelect from '../content/favorites/FavoritesPropertySelect';
import AdvListPagination from '../content/AdvList/selectcomponent/AdvListPagination';
import { FavoritesFields } from '../../types/emuns/favoritesFields';
import TextInput from '../inputs/TextInput';

interface IFavoritesFormProps {
    innerRef: React.MutableRefObject<any>
    config: IFavoritesConfig[]
    translations: IFormTranslations;
    onClick: (values: IFavoritesForm) => void
}

const FavoritesForm: React.FunctionComponent<IFavoritesFormProps> = (props) => {
    const { innerRef, config, translations, onClick } = props
    const [visible, setVisible] = React.useState(false);
    const clickHandler = () => {
        setVisible(!visible)
    }
    return <div className='applications-search-form'>
        {
            !visible &&
            <div className="filter-show-btn" onClick={clickHandler}>
                <i className="fa-solid fa-bars"></i> <span>{translations.show_filter_btn}</span>
            </div>
        }
        <div className={`search-form-wrapper ${visible ? '' : 'hiden'}`}>
            <Form<IFavoritesForm>
                schema={{
                    initialValues: favoritesInitialValues,
                    onSubmit: onClick,
                }}
                innerRef={props.innerRef}
                enableReinitialize>
                <div className={`search-form favorites-search-form`}>
                    <h2>{translations.titleRequests} {visible && <i className="fa-solid fa-xmark" onClick={clickHandler}></i>}</h2>
                    <div className="search-form-inner">
                        <FavoritesPropertySelect config={[]} label={translations.property_label} defaultTransl={translations.all_placeholder} />
                        <TextInput name={FavoritesFields.ADV_ID} label={translations.id_label} placeholder={translations.id_placeholder} />
                        <AdvListPagination label={translations.pagination_label} field={FavoritesFields.PER_PAGE} />
                    </div>
                    <div className='btns-wrapper'>
                        <button type='reset' className='adv_list_reset_btn  form-btn'>{translations.clear_btn}</button>
                        <button type='submit' className='adv_list_submit_btn form-btn'>{translations.search_btn}</button>
                    </div>
                </div>

            </Form>
        </div>
    </div>
};

export default FavoritesForm;
