import { useField } from "formik";
import * as React from "react";

interface ITextInputProps {
  label?: string;
  type?: string;
  name: string;
  id?: string;
  image?: string;
  inputImage?: React.ReactNode;
  placeholder?: string;
  withError?: true;
}

const TextInput: React.FunctionComponent<ITextInputProps> = ({
  label,
  type,
  id,
  image,
  inputImage,
  placeholder,
  withError,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <label className={`label ${withError ? "with-error" : ""}`}>
      <div className="label-wrapper">
        <span className="label-text">{label}</span>{" "}
        {image && <img src={image} alt="" className="label-image" />}
      </div>
      <div className="input-wrapper">
        {inputImage && inputImage}
        <input
          className="input"
          type={type ?? "text"}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          placeholder={placeholder}
        />
      </div>
      <div className="error-wrapper">
        {meta.touched && meta.error && (
          <span className="error_msg">{meta.error}</span>
        )}
      </div>
    </label>
  );
};

export default TextInput;
