import * as React from 'react';
import './index.scss';
interface ICopyButtonProps {
    name: string;
    onClick: () => void
}

const CopyButton: React.FunctionComponent<ICopyButtonProps> = (props) => {
    const { name, onClick } = props
    return <div onClick={onClick} className='copy-btn'>
        <i className="fa-regular fa-copy"></i>
        <p className='copy-btn-text'>{name}</p>
    </div>;
};

export default CopyButton;