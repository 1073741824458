import * as React from 'react';
import './emptyList.scss'
interface IEmptyListProps {
    text: string
}

const EmptyList: React.FunctionComponent<IEmptyListProps> = (props) => {
    return <div className="empty-list">
        <p>{props.text}</p>
    </div>;
};

export default EmptyList;
