import { object, string } from "yup";
import { RequestsFields } from "../../types/emuns/requestsFields";

export interface IReplyForm {
    id: number;
    [RequestsFields.REPLY_MESSAGE]: string;
}

export const replyInitialValues: IReplyForm = {
    id: 0,
    [RequestsFields.REPLY_MESSAGE]: ""
}

export const replyShema = object({
    [RequestsFields.REPLY_MESSAGE]: string().required(),
})