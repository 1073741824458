import * as React from "react";
import Logo from "../components/logo/Logo";
import Navbar from '../components/navbars/Navbar';
import { NavLink } from "react-router-dom";
import DropMenu from "../components/dropdown/DropMenu";
import axios from "axios";

import { StoreContext } from "../store/StoreProvider";
import { IHeader } from "../types/responses/translations";
import { BASE_PATH, BASE_STORAGE_PATH } from "../api/axios";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
interface IHeaderProps {
  menu: Array<IHeader>,
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { store, setLang, setLangIschanged, setCurrency, setCurrencyIsChanged } = React.useContext(StoreContext);
  const langs = store.langs.map((lang, i) => {
    return {
      key: (i + 1).toString(),
      label: (
        <div className="options" onClick={() => changeLanguage(lang.code)}>
          <img src={BASE_STORAGE_PATH + lang.icon} alt="" className="select-option-img" />
          < span>{lang.code.toUpperCase()}</span>
        </div>
      )
    };
  })

  const currencies = store.currencies.map((curr, i) => {
    return {
      key: (i + 1).toString(),
      label: (
        <div className="options" onClick={() => changeCurrency(curr.code)}>
          <div className="options">
            <span>{curr.symbol} {curr.code}</span>
          </div>
        </div>
      )
    };
  })
  const changeLanguage = (language: string) => {
    if (language !== store.lang) {
      axios
        .post(BASE_PATH + "/api/lang", {
          lang: language,
        })
        .then((res) => {
          if (res.status === 200) {
            setLang(language);
            setLangIschanged(true);
          }
        });
    }
  }
  const changeCurrency = (currency: string) => {
    if (currency !== store.currency) {
      axios
        .post(BASE_PATH + "/api/currency", {
          currency: currency,
        })
        .then((res) => {
          if (res.status === 200) {
            setCurrency(currency);
            setCurrencyIsChanged(true);
          }
        });
    }
  }
  return (
    <header className="header">
      <div className="container">
        <div className="header-inner">
          <Logo />
          <Navbar menu={props.menu} />
          <DropMenu
            items={langs}
          >
            <div className="options">
              <img src={BASE_STORAGE_PATH + store.langs.find(lang => lang.code === store.lang)?.icon} alt="" className="select-option-img" />
              <span>{store.lang.toUpperCase()}</span>
            </div>
          </DropMenu>
          <DropMenu
            items={currencies}
          >
            <div className="options">
              <span>{store.currencies.find((cur) => cur.code === store.currency)?.symbol}</span>
              <span>{store.currencies.find((cur) => cur.code === store.currency)?.code}</span>
            </div>
          </DropMenu>
          <NavLink to={'/account/anoucements/create-ad'} className="sell-link">
            {props.menu && '+ ' + props.menu.find(el => el.url === 'sell-link')?.name}
          </NavLink>
          <div className="notifications">
            <i className="fa-regular fa-bell"></i>
          </div>
          <DropMenu
            items={[
              {
                key: "1",
                label: (
                  <NavLink to={"/logout"}>Logout</NavLink>
                ),
              },
            ]}
          >
            <div className="user-icon">
              <i className="fa-regular fa-user"></i>
            </div>
          </DropMenu>


        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
