import * as React from "react";

import {  Dropdown, Space } from "antd";
interface IDropMenuProps {
  items: Array<{
    key: string;
    label: React.ReactNode;
  }>;
  children: any;
}

const DropMenu: React.FunctionComponent<IDropMenuProps> = (props) => {
  const { items,} = props;
  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomLeft"
        >
          {props.children}
        </Dropdown>
      </Space>
    </Space>
  );
};

export default DropMenu;
