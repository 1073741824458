import * as React from 'react';
import { ICurrency, ILang, ILeftSidebar } from '../types/responses/translations';
import { IUser } from '../types/responses/user';
export const StoreContext = React.createContext<{
    store: {
        lang: string;
        langs: Array<ILang>
        langIsChanged: boolean;
        currency: string;
        currencyIsChanged: boolean;
        currencies: ICurrency[];
        user: IUser | null;
        leftSidebar: Array<ILeftSidebar>
        updated_lang: string
    };
    setLang: React.Dispatch<React.SetStateAction<string>>;
    setCurrency: React.Dispatch<React.SetStateAction<string>>;
    setUpdatedLang: React.Dispatch<React.SetStateAction<string>>;
    setLangIschanged: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrencyIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
    setLangs: React.Dispatch<React.SetStateAction<ILang[]>>;
    setCurrencies: React.Dispatch<React.SetStateAction<ICurrency[]>>;
    setLeftSidebar: React.Dispatch<React.SetStateAction<Array<ILeftSidebar>>>;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;

}>({
    store: {
        lang: 'en',
        langIsChanged: false,
        currency: "USD",
        currencyIsChanged: false,
        currencies: [],
        langs: [],
        user: null,
        leftSidebar: [],
        updated_lang: '',
    },
    setLang: () => null,
    setCurrency: () => null,
    setCurrencies: () => null,
    setCurrencyIsChanged: () => null,
    setLangIschanged: () => null,
    setLangs: () => null,
    setUser: () => null,
    setLeftSidebar: () => null,
    setUpdatedLang: () => null
});

interface IStoreProviderProps {
    children: React.ReactNode;
}

const StoreProvider: React.FunctionComponent<IStoreProviderProps> = (props) => {
    const [langs, setLangs] = React.useState(Array<ILang>);
    const [lang, setLang] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [currencies, setCurrencies] = React.useState(Array<ICurrency>);
    const [currencyIsChanged, setCurrencyIsChanged] = React.useState(false);
    const [updatedLang, setUpdatedLang] = React.useState('');
    const [langIsChanged, setLangIschanged] = React.useState(false);
    const [LeftSidebar, setLeftSidebar] = React.useState<Array<any>>([]);
    const [user, setUser] = React.useState<IUser | null>(null);
    return <StoreContext.Provider value={{
        store: {
            lang: lang,
            langIsChanged: langIsChanged,
            langs: langs,
            currency: currency,
            currencies: currencies,
            currencyIsChanged: currencyIsChanged,
            user: user,
            leftSidebar: LeftSidebar,
            updated_lang: updatedLang
        },
        setLang,
        setLangIschanged,
        setLangs,
        setLeftSidebar,
        setUser,
        setUpdatedLang,
        setCurrency,
        setCurrencies,
        setCurrencyIsChanged,
    }}>
        {props.children}
    </StoreContext.Provider>;
};

export default StoreProvider;

