import { IFooter, IHeader, ILeftSidebar, ITransation } from "../types/responses/translations";

export const sortMenuTranslations = (array: ITransation[]) => {
    const header: Array<IHeader> = [];
    const footer: Array<IFooter> = [];
    const sidebar: Array<ILeftSidebar> = [];
    let id = 0;
    array.forEach((item: ITransation) => {
        if (item.position === 'header') {
            header.push({
                name: item.name,
                url: item.url
            })
        }
        if (item.position === 'footer') {
            footer.push({
                name: item.name,
                url: item.url
            })
        }
        if (item.position === 'sidebar') {
            if (!item.parrent) {
                sidebar.push({
                    name: item.name,
                    url: item.url,
                    submenu: null
                })
            } else {
                if (id === 0) {
                    const index = sidebar.findIndex((el: ILeftSidebar) => el.url === item.parrent);
                    id = index
                }
                if (!sidebar[id].submenu) {
                    sidebar[id].submenu = [];
                }
                sidebar[id].submenu?.push({
                    name: item.name,
                    url: item.url,
                })
            }
        }
    })
    return {
        header: header,
        footer: footer,
        sidebar: sidebar
    }
}