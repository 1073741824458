const alowedExtentions = ["jpeg", "jpg", "webp", "svg", "png"];
export const checkImage = (
  file: File,
  error: { size: string; ext: string }
) => {
  const fileExtention = file.name.split(".").pop();
  if (fileExtention) {
    if (!Object.values(alowedExtentions).includes(fileExtention)) {
      return error.ext;
    }
  }
  if (file.size > 10000000) {
    return error.size;
  }
  return null;
};
