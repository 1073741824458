import * as React from 'react';
import { IListCard } from '../../types/data/requestsData';
import './applicationsReplyCard.scss'
import { IRequestsListTranslations } from '../../types/responses/translations/applicationTranslations';
import SumbitButton from '../buttons/submitButton/SubmitButton';
import OutlineButton from '../buttons/outlineButton/OutlineButton';
import { truncate } from '../../utils/truncateText';
import DOMPurify from 'dompurify';
interface IApplicationReplyCardProps {
    card: IListCard;
    translations: IRequestsListTranslations;
    preview: () => void
    toArchive: (index: number) => void;
}

const ApplicationReplyListCard: React.FunctionComponent<IApplicationReplyCardProps> = (props) => {
    const { card, translations, preview, toArchive } = props
    const toArchiveHandler = (index: number) => () => {
        toArchive(index)
    }
    return (
        <div className='reply-card'>
            <p className='reply-card-sender'>
                {card.user_name}
            </p>
            <div className="reply-card-sender-info">
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-solid fa-location-dot"></i>
                    {card.incoming_location}
                </p>
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-regular fa-calendar"></i>
                    {card.created_data}
                </p>
                <p className='reply-card-sender-info-text-wrapper'>
                    <i className="fa-regular fa-envelope"></i>
                    {card.user_email}
                </p>
            </div>
            <p className='reply-card-message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncate(card.incoming_message)) }}>
                {/* {truncate(card.incoming_message)} */}
            </p>
            <div className="buttons-wrapper">
                {
                    card.active !== 0 && <OutlineButton onClick={toArchiveHandler(card.request_id)} text={translations.archive_btn} />
                }
                <SumbitButton onClick={preview} text={translations.review_btn} />
            </div>
        </div>
    );
};

export default ApplicationReplyListCard;
