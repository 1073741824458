import * as React from 'react';
import './index.scss';
interface IUploadButtonProps {
    multiple?: boolean
    label: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const UploadButton: React.FunctionComponent<IUploadButtonProps> = (props) => {
    const { label, multiple, onChange } = props;
    return <label >
        <div className='upload-text-wrapper'>
            <i className="fa-solid fa-download"></i>
            <p className='upload-label'>{label}</p>
        </div>
        <input type="file" name='image' hidden multiple={multiple} onChange={onChange} />
    </label>;
};

export default UploadButton;
