import * as React from "react";
import { IRoomsTranlations } from "../../../../types/responses/translations/advertisementTranslations";
import NumberInput from "../../../inputs/NumberInput";
import "./rooms.scss";
import { AdvFields } from "../../../../types/emuns/advfields";
interface IRoomsModuleProps {
  translations: IRoomsTranlations;
}

const RoomsModule: React.FunctionComponent<IRoomsModuleProps> = (props) => {
  const { translations } = props;
  return (
    <div className="module">
      <h2>{translations.title}</h2>
      <div className="rooms-inputs-wrapper">
        <NumberInput
          name={AdvFields.ROOMS}
          label={translations.rooms_label}
          placeholder={translations.placeholder}
          withError
        />
        <NumberInput
          name={AdvFields.BEDROOMS}
          label={translations.bedrooms_label}
          placeholder={translations.placeholder}
          withError
        />
        <NumberInput
          name={AdvFields.BATHROOMS}
          label={translations.bathrooms_label}
          placeholder={translations.placeholder}
          withError
        />
      </div>
    </div>
  );
};

export default RoomsModule;
