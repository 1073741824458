export enum RequestsFields{
    ADV_ID='adv_id',
    PHONE_NUMBER='phone_number',
    ADV_TYPE='adv_type',
    PROPERTY_TYPE='property_type',
    REPLY_STATUS='reply_status',
    RESPONSYBLE_PERSON='responsible_person',
    PER_PAGE='per_page',
    STATUS = "status",
    REPLY_MESSAGE='reply_message'
}