import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { BASE_PATH } from "../api/axios";
import { StoreContext } from "../store/StoreProvider";
import { IProfileTranslationsResponce } from "../types/responses/translations/profileTranslations";
import { FormikValues } from "formik";

export const useProfileData = () => {
  const { store } = useContext(StoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IProfileTranslationsResponce>();

  //load data
  const getProfileData = useCallback(() => async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(BASE_PATH + "/api/profile");
      setData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [])

  //get multi language fields
  const loadMultiData = async (value: string) => {
    try {
      if (value) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("lang", value);
        const res = await axios.post(
          BASE_PATH + "/api/profile/multidata",
          formData
        );
        setIsLoading(false);
        if (res.status === 200) {
          return res.data;
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  //get user data only if app language and updated language euqal to update left sidebar user information
  const getUser = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(BASE_PATH + "/api/user/get");
      setIsLoading(false);
      if (data) {
        return { ...data, langs: JSON.parse(data.langs) };
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  //submit form
  const onSubmit = async (data: FormikValues) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      for (const key in data) {
        const element = (data as any)[key];
        if (Array.isArray(element)) {
          formData.append(key, JSON.stringify(element ? element : ""));
        } else formData.append(key, element ? element : "");
      }
      await axios.post(BASE_PATH + "/api/user/update", formData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  //image load and delete
  const loadImage = async (file: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('image', file)
      const { data } = await axios.post(BASE_PATH + '/api/user/update/image', formData)


      return data
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false)

    }
  }
  const deleteImage = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(BASE_PATH + '/api/user/delete/image')
      setIsLoading(false);
      if (res) {
        return true
      }
    } catch (error) {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    getProfileData()();
  }, []);

  //handling if app language is change
  useEffect(() => {
    if (store.langIsChanged) {
      getProfileData()();
    }
  }, [getProfileData, store.langIsChanged]);
  return {
    isLoading,
    data,
    onSubmit,
    loadMultiData,
    deleteImage,
    getUser,
    loadImage,
  };
};
