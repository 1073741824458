import * as React from "react";
import "./index.scss";
interface ILoaderProps {
  text: string;
}

const Loader: React.FunctionComponent<ILoaderProps> = (props) => {
  return (
    <div className="loader-wrapper">
      <div className="wrapper">
        <div className="loader-box">
          <div className="loader"></div>
          <div className="loader-text">{props.text + '...'}</div>
        </div>
      </div>
      ;
    </div>
  );
};

export default Loader;
