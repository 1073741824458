import * as React from 'react';

interface ITooltipProps {
    text: string
}

const Tooltip: React.FunctionComponent<ITooltipProps> = (props) => {
    return <div className="tooltip">
        <span className='tooltip-icon'>i</span>
        <span className="tooltiptext">{props.text}</span>
    </div>;
};

export default Tooltip;
