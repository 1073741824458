import * as React from "react";
import { IPaginationLink, IPaginationObject } from "../../types/pagination";
import "./pagination.scss";
interface IPaginationProps {
  pagination: React.MutableRefObject<IPaginationObject>;
  params?: any;
}

const Pagination: React.FunctionComponent<IPaginationProps> = (props) => {
  const { pagination, params } = props;
  const { data, click, prev, next } = pagination.current;
  const [links, setLinks] = React.useState<IPaginationLink[] | null>(null);

  const prevHandler = () => {
    prev(params);
  };
  const nextHandler = () => {
    next(params);
  };
  const clickHandler = (url: string | null) => () => {
    if (url) {
      window.scrollTo(0, 0);
      click(url, params);
    }
  };
  React.useEffect(() => {
    if (data.links) {
      const links = [...data.links];
      links.pop();
      links.shift();
      setLinks(links);
    }
  }, [data.links]);
  return (
    <div className="pagination">
      <button
        type="button"
        onClick={prevHandler}
        disabled={data.prev_page_url ? undefined : true}
      >
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      <ul className="pagination-list">
        {links &&
          links.map((link, i) => (
            <li
              key={i}
              className={`pagination-list-item ${
                data.current_page === +link.label ? "current-link" : ""
              }`}
              onClick={clickHandler(link.url)}
            >
              {+link.label < 10 ? "0" + link.label : link.label}
            </li>
          ))}
      </ul>
      <button
        type="button"
        onClick={nextHandler}
        disabled={data.next_page_url ? undefined : true}
      >
        <i className="fa-solid fa-arrow-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
