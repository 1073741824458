import * as React from 'react';
import { IFooter } from '../types/responses/translations';
import Logo from '../components/logo/Logo';
import { NavLink } from 'react-router-dom';
import { BASE_PATH } from '../api/axios';
import { ISocialMenu } from '../types/responses/layoutResponce';

interface IFooterProps {
  menu: Array<IFooter>
  social: Array<ISocialMenu>
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const { menu, social } = props
  return <div className="container">
    <footer className='footer'>
      <div className="footer-inner">
        <Logo />
        <ul className='footer-links-wrapper'>
          {menu && menu.map((item, i) => (
            <li key={i}>
              <NavLink to={`/${item.url}`}>{item.name}</NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-inner">
        <ul className='footer-links-wrapper'>
          {social && social.map((el, i) =>
            <li key={i}>
              <a href={el.url}>
                <img src={BASE_PATH + el.path} alt="" />
              </a>
            </li>)}
        </ul>
        <span>
          © 2023 All Rights Reserved
        </span>
      </div>
    </footer>
  </div>;
};

export default React.memo(Footer);
