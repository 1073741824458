import * as React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import MainPreviewImage from "./MinPreviewImage";
import UploadButton from "../../buttons/uploadbutton/UploadButton";
import { IPhoto } from "../../../types/responses/translations/advertisementTranslations";
import { BASE_PATH } from "../../../api/axios";
import "./DropPhotoModuleComponent.scss";
interface IDropPhotoComponentProps {
  translations: IPhoto;
  hasPrewiew?: true;
  error?: string | string[];
  images: string[] | null;
  deleteImage: (i: number) => void;
  onLoad: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleDrop: (event: DragEvent) => Promise<void>;
  onSortEnd: (oldIndex: number, newIndex: number) => Promise<void>;
}

const DropPhotoComponent: React.FunctionComponent<IDropPhotoComponentProps> = (
  props
) => {
  const {
    images,
    hasPrewiew,
    translations,
    error,
    deleteImage,
    onLoad,
    handleDrop,
    onSortEnd,
  } = props;
  const [dragOver, setDragOver] = React.useState(false);
  const handlerOndrop = (e: any) => {
    handleDrop(e);
    setDragOver(false);
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  const onSortEndHandler = async (oldIndex: number, newIndex: number) => {
    onSortEnd(oldIndex, newIndex);
  };
  const deleteHandler = (i: number) => async () => {
    deleteImage(i);
  };
  return (
    <div className="photo-module module">
      <div className="photo-module-inner">
        <MainPreviewImage image={images ? images[0] : null} isPrewiew={hasPrewiew} />
        <div
          onDrop={handlerOndrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className={`drop-zone ${dragOver ? "drag-over" : ""}`}
        >
          <h2>{translations.title}</h2>
          <p className="photo-descr">
            {translations.description}{" "}
            <i className="fa-solid fa-circle-exclamation"></i>
          </p>
          <UploadButton
            label={translations.button}
            onChange={onLoad}
            multiple
          />
        </div>
        {error && <span className="error_msg">{error}</span>}
        <div className="preview-images">
          <SortableList
            onSortEnd={onSortEndHandler}
            className="list"
            draggedItemClassName="dragged"
          >
            {images &&
              images.map((image, i) => (
                <SortableItem key={image}>
                  <div className="preview-img-card">
                    <div className="icons">
                      {i === 0 && hasPrewiew && (
                        <div className="icon-bg">
                          <div className="firsr-image">
                            <span>1</span>
                          </div>
                        </div>
                      )}
                      <div className="icon-bg grab">
                        <i className="fa-solid fa-up-down-left-right"></i>
                      </div>
                      {
                        images.length > 1 &&
                        < div className="icon-bg click" onClick={deleteHandler(i)}>
                          <i className="fa-regular fa-trash-can"></i>
                        </div>
                      }
                    </div>
                    <img
                      src={BASE_PATH + "/storage" + image}
                      alt=""
                      key={i}
                      className="prewiew-img"
                      draggable="false"
                    />
                  </div>
                </SortableItem>
              ))}
          </SortableList>
        </div>
      </div>
    </div >
  );
};

export default DropPhotoComponent;
