import * as React from "react";
import General from "./rightSidebar/General";
import Legend from "./rightSidebar/Legend";
import { ISidebarTranslationData } from "../../types/responses/translations/generalTranslations";

interface IRightSidebarProps {
  innerRef: React.MutableRefObject<any>;
  translations: ISidebarTranslationData | null;
  onChange?: (value: string) => void;
  publicMark?: true;
  langMark?: true;
  indentifyMark?: true;
}

const RightSidebar: React.FunctionComponent<IRightSidebarProps> = (props) => {
  const {
    translations,
    innerRef,
    publicMark,
    langMark,
    indentifyMark,
  } = props;
  return (
    <div>
      {translations?.general && (
        <General
          translations={translations.general}
          onChange={props.onChange}
          innerRef={innerRef}
        />
      )}
      {translations?.legend && (
        <Legend
          translations={translations.legend}
          publicMark={publicMark}
          langMark={langMark}
          indentifyMark={indentifyMark}
        />
      )}
    </div>
  );
};

export default RightSidebar;
