import * as React from "react";
import { IHeader } from "../../types/responses/translations";
import { BASE_PATH } from "../../api/axios";

interface INavbarProps {
  menu: Array<IHeader>
}

const Navbar: React.FunctionComponent<INavbarProps> = (props) => {
  const sellLink = props.menu.find(el => el.url === 'sell-link')
  return (
    <div className="nav">
      <ul>
        {props.menu && props.menu.map((item, i) => {
          if (item !== sellLink) {
            return (
              <li key={i}>
                <a className="nav-link" href={`${BASE_PATH}/${item.url}`}>
                  {item.name}{" "}
                </a>
              </li>
            )
          }
          return null
        })}
      </ul>
    </div>
  );
};

export default Navbar;
