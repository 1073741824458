import React from "react";
import { SystemMessage } from "../types/emuns/systemMessage";

export const SystemMessageContext = React.createContext<{
    isOpen: boolean;
    type: string;
    title: string;
    message: string;
    openNotificationWindow: (type: SystemMessage, title: string, message: string) => void;
    closeNotificationWindow: () => void;
}>({
    isOpen: false,
    type: '',
    title: '',
    message: '',
    openNotificationWindow: () => null,
    closeNotificationWindow: () => null
});

interface ISystemMessageProviderProps {
    children: React.ReactNode;
}
const SystemMessageProvider: React.FunctionComponent<ISystemMessageProviderProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [type, setType] = React.useState('success');
    const [title, setTitle] = React.useState('success');
    const [message, setMessage] = React.useState('some success message');
    const openNotificationWindow = (type: string, title: string, message: string) => {
        setIsOpen(true);
        setType(type);
        setTitle(title);
        setMessage(message);
        setTimeout(() => {
            setIsOpen(false);
            setType('');
            setTitle('');
            setMessage('');
        }, 3000);
    }
    const closeNotificationWindow = () => {
        setIsOpen(false)
    }
    return <SystemMessageContext.Provider value={{
        isOpen: isOpen,
        type: type,
        title: title,
        message: message,
        openNotificationWindow,
        closeNotificationWindow
    }}>
        {props.children}
    </SystemMessageContext.Provider>;
};

export default SystemMessageProvider;