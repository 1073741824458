import * as React from 'react';
import { useFavoritesData } from '../hooks/useFavoritesData';
import Main from '../layouts/Main';
import FavoritesForm from '../components/forms/FavoritesForm';
import { IFavoritesForm } from '../types/data/favoritesForm';
import { FormikProps } from 'formik';

interface IFavoritesListProps {
}

const FavoritesList: React.FunctionComponent<IFavoritesListProps> = (props) => {
  const { translations, mainTranslations } = useFavoritesData();
  const innerRef = React.useRef<FormikProps<IFavoritesForm>>();

  const submitHandler = () => {

  }
  return (
    <Main
      title={mainTranslations?.titleFavorites}
      breadcrumbs={[
        {
          link: "/account",
          descr: mainTranslations?.breadcrumbs.home,
        },
        {
          link: "/account/profile",
          descr: mainTranslations?.breadcrumbs.profile,
        },
        {
          link: "/account/favorites",
          descr: mainTranslations?.breadcrumbs.favorites,
        },
      ]}
    >
      <div className="adv-list-content">
        <div className="control-panel-wrapper">
          {translations &&
            <FavoritesForm innerRef={innerRef} config={[]} translations={translations} onClick={submitHandler} />
          }
        </div>
      </div>
    </Main>
  );;
};

export default FavoritesList;
