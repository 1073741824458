import { IRequestsForm } from "../../types/data/requestsForm";
import { RequestsFields } from "../../types/emuns/requestsFields";

export const requestsInititalValues: IRequestsForm = {
  [RequestsFields.ADV_ID]: "",
  [RequestsFields.ADV_TYPE]: "0",
  [RequestsFields.PHONE_NUMBER]: "",
  [RequestsFields.PROPERTY_TYPE]: "0",
  [RequestsFields.REPLY_STATUS]: "0",
  [RequestsFields.RESPONSYBLE_PERSON]: "",
  [RequestsFields.PER_PAGE]: 10,
  [RequestsFields.STATUS]: ""
};
