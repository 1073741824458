import * as React from "react";
import { Form } from "./Form";
import { FormikValues } from "formik";
import { IAdvInitialValues } from "../../types/data/advInitialValues";
import { IAdvTranslatonsData } from "../../types/responses/translations/advertisementTranslations";
import { advShema } from "../../validation/advertisementShema/advShema";
import AdvTypeModule from "../content/advertisement/advtype/AdvTypeModule";
import VideoLink from "../content/advertisement/link/VideoLink";
import LocationModule from "../content/advertisement/location/LocationModule";
import ContractDetails from "../content/advertisement/contact/ContactModule";
import GeneralModule from "../content/advertisement/general/GeneralModule";
import PhotoModule from "../content/advertisement/photo/PhotoModule";
import { IAdvConfig } from "../../types/data/advConfig";
import FloorPlanModule from "../content/advertisement/floorPlan/FloorPlan";
import { ApiImageDestination } from "../../types/emuns/ImageDestinaionEnum";
import PriceModule from "../content/advertisement/price/PriceModule";
import PointOfInterestsModule from "../content/advertisement/pointsofinterests/PointOfInterestsModule";
import Area from "../content/advertisement/area/Area";
import RoomsModule from "../content/advertisement/rooms/RoomsModule";
import DescriptionModule from "../content/advertisement/description/DescriptionModule";
import LangMark from "../../images/icons/legend/translate.png";
import FiltersModule from "../content/advertisement/filters/FiltersModule";

interface IAdvFormProps {
  translations: IAdvTranslatonsData;
  initialValues: IAdvInitialValues;
  innerRef: React.MutableRefObject<any>;
  deleteImage: (
    image: string,
    destination: ApiImageDestination
  ) => Promise<void>;
  loadImageOnServer: (
    file: File,
    destination: ApiImageDestination
  ) => Promise<any>;
  config: IAdvConfig;
  onSubmit: (data: FormikValues) => Promise<void>;
}

const AdvForm: React.FunctionComponent<IAdvFormProps> = (props) => {
  const {
    translations,
    initialValues,
    innerRef,
    deleteImage,
    loadImageOnServer,
    onSubmit,
    config,
  } = props;

  const submitHandler = (
    values: IAdvInitialValues,
  ) => {
    onSubmit(values);
  };
  return (
    <Form<IAdvInitialValues>
      schema={{
        initialValues: initialValues,
        onSubmit: submitHandler,
        validationSchema: advShema,
      }}
      enableReinitialize
      innerRef={innerRef}
    >
      <PhotoModule
        translations={translations.photo}
        deleteImage={deleteImage}
        loadImageOnServer={loadImageOnServer}
      />
      <FloorPlanModule
        translations={translations.flor_plan}
        deleteImage={deleteImage}
        loadImageOnServer={loadImageOnServer}
      />
      <AdvTypeModule
        translations={translations.type}
        options={config.adv_types}
      />
      <VideoLink translations={translations.link} />
      <PriceModule translations={translations.price} />
      <LocationModule translations={translations.location} options={config.countries} />
      <PointOfInterestsModule
        translations={translations.points_of_interests}
        options={config.points_of_interests}
      />
      <ContractDetails translations={translations.contact_details} />
      <GeneralModule translations={translations.general} options={config} />
      <Area translations={translations.area} />
      <RoomsModule translations={translations.rooms} />
      <DescriptionModule
        translations={translations.description}
        image={LangMark}
      />
      <FiltersModule
        translations={translations.filters}
        config={config.filters}
      />
    </Form>
  );
};

export default AdvForm;
